import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import ConfirmDialog from '../dialogs/ConfirmDialog';
import WarningDialog from '../dialogs/WarningDialog';
import { updateAlertDialog, toggleWarningDialog } from '../../actions/dialog/dialog';
import { updateUser } from '../../actions/registration/registration';
import { updateSendingRequest, updatePage } from '../../actions/app/app';
import * as api from '../../actions/api/requests';
import { countries } from '../../constants/countries'
import moment from 'moment';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%",
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Registration extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      handleAgree : this.handleAgree.bind(this),
      dialogTitle : "Confirm",
      dialogText  : "Click Yes when you are ready to proceed and take the IELTS mock test.",
      yesBtnTitle : "Yes",
      noBtnTitle  : "No",
      warningBtnTitle : "Ok, I understood",
      warningTitle    : "Error",
      warningText     : "Please fill all the missing values that are in red.",
    };
  }
  toggleWarningDialog(){
    const { dispatch } = this.props;
    dispatch(toggleWarningDialog());
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    if (this.checkInput()){
      dispatch(updateAlertDialog(true));
    } else {
      this.toggleWarningDialog();
    }
  }
  // Returns valid user inputs
  checkInput(){
    const { user } = this.props;
    if (
      user.name  === ""  ||
      user.email === ""  ||
      user.t_score === 0 ||
      (user.actual_country === "" && user.country === "") ||
      (user.actual_country === "Invalid IP Address" && user.country === "") ||
      (!user.actual_country  && user.country === "") ||
      user.plan_exam_date === "" ||
      (user.taken_exam && user.p_score===0)
    ){
      return false;
    }
    return true;
  }
  handleAgree = () => {
    const { dispatch, history, user, other_country,exam_set, actual_country } = this.props;
    dispatch(updateSendingRequest(true));
    dispatch(updateAlertDialog(false));
    let countries = user.target_country.toString();
    if (other_country !== ""){
      countries += "," + other_country;
    }
    user['actual_country'] = actual_country;
    user.target_country = countries;
    user['exam_set'] = exam_set;
    if (user.actual_country && user.actual_country !== "" && user.actual_country !== "Invalid IP Address"){
      user.country = user.actual_country;
    }
    dispatch(api.createUser(user, history));
  }
  handleInput = (e) => {
    const { dispatch, user } = this.props;
    const newUser = {...user};
    newUser[e.target.name] = e.target.value;
    dispatch(updateUser(newUser));
  }
  handleRadio = (e) => {
    const { dispatch, user } = this.props;
    const newUser = {...user};
    newUser.taken_exam = e.target.value==='yes';
    if (e.target.value==='no'){
      newUser.p_score = 0;
    }
    dispatch(updateUser(newUser));
  }
  handleTargetCountryChange = (e) => {
    const { dispatch, target_country, user } = this.props;
    const value = e.target.value;
    let new_t_countries = target_country.slice(0);
    if (e.target.checked){
      new_t_countries.push(value);
    } else {
      const i = new_t_countries.indexOf(value);
      new_t_countries.splice(i, 1);
    }
    const newUser = {...user};
    newUser.target_country = new_t_countries;
    dispatch(updateUser(newUser));
  }
  componentDidMount(){
    const { dispatch, user } = this.props;
    dispatch(updateSendingRequest(false));
    dispatch(api.getExamSet(user.code));
  }
  render() {
    const {
      classes,
      user,
      dispatch,
      done,
      target_country,
      other_country,
      sendingRequest,
    } = this.props;
    if (done){
      dispatch(updatePage(5));
    }
    const country_list = countries.map((country) => {
      return {
        value: country.Name,
        label: country.Name,
      };
    });
    const bandScores = [];
    for (let i = 5.0; i <= 9.0; i = i + 0.5){
      bandScores.push(i.toString());
    }
    const potentialDates = [];
    potentialDates.push(moment().format('MMMM YYYY'));
    potentialDates.push(moment().add(1, 'M').format('MMMM YYYY'));
    potentialDates.push(moment().add(2, 'M').format('MMMM YYYY'));
    potentialDates.push(moment().add(3, 'M').format('MMMM YYYY'));
    potentialDates.push("After " + moment().add(4, 'M').format('MMMM YYYY'));
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <div>
                <Typography variant="headline" component="h5">
                  Please choose an answer below
                </Typography>
                <br/>
                <Typography variant="headline" component="h6">
                  Have you taken the IELTS exam before?
                </Typography>
                <span className="questions">
                  <RadioGroup
                    aria-label="taken_exam"
                    name="taken_exam"
                    value={user.taken_exam===""?"":user.taken_exam?"yes":"no"}
                    className={classes.group}
                  >
                    <FormControlLabel
                      className="form-have-taken"
                      value="yes"
                      control={<Radio onClick={this.handleRadio.bind(this)} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      className="form-have-taken"
                      value="no"
                      onClick={this.handleRadio.bind(this)}
                      control={<Radio onClick={this.handleRadio.bind(this)} />}
                      label="No"
                    />
                  </RadioGroup>
                </span>
              </div>
              {
                user.taken_exam!==""?
                  user.taken_exam?
                    <div>
                      <br/>
                      <span className="questions">
                        <Typography variant="headline" component="h6">
                          What was your last/latest IELTS band score?
                        </Typography>
                        <TextField
                          id="standard-select-currency-p_score"
                          select
                          name="p_score"
                          onChange={this.handleInput.bind(this)}
                          value={user.p_score}
                          helperText="IELTS band score"
                          margin="normal"
                          error={user.p_score === 0}
                          required
                        >
                          {bandScores.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </span><br/><br/><br/>
                      <span className="questions">
                        <Typography variant="headline" component="h6">
                          When do you plan to take the IELTS test again?
                        </Typography>
                        <TextField
                          id="standard-select-currency-plan_exam_date"
                          select
                          name="plan_exam_date"
                          onChange={this.handleInput.bind(this)}
                          value={user.plan_exam_date}
                          error={user.plan_exam_date===""}
                          helperText="Planned exam date"
                          margin="normal"
                          required
                        >
                          {potentialDates.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </span><br/><br/><br/>
                      <span className="questions">
                        <Typography variant="headline" component="h6">
                          What is your target IELTS band score?
                        </Typography>
                        <TextField
                          id="standard-select-currency-p_score"
                          select
                          name="t_score"
                          onChange={this.handleInput.bind(this)}
                          value={user.t_score}
                          error={user.t_score===0}
                          helperText="IELTS band score"
                          margin="normal"
                          required
                        >
                          {bandScores.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </span>
                      <br/><br/><br/>
                      {
                        user.actual_country === "" || user.actual_country === "Invalid IP Address"  || !user.actual_country?
                          <span className="questions">
                            <Typography variant="headline" component="h6">
                              Which country are you living in <b><u>NOW</u></b>?
                            </Typography>
                            <TextField
                              id="standard-select-currency"
                              select
                              name="country"
                              onChange={this.handleInput.bind(this)}
                              value={user.country}
                              error={user.country===""}
                              helperText="Please select your country"
                              margin="normal"
                              required
                            >
                              {country_list.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <br/><br/><br/>
                          </span>
                            :
                          null
                      }
                      <span className="questions">
                          <Typography variant="headline" component="h6">
                            {"If you're taking the IELTS exam for immigration, which country are you hoping to go – (select up to two options):"}
                          </Typography>
                          <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="Australia"
                                  checked={target_country.indexOf('Australia') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="Australia"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="Canada"
                                  checked={target_country.indexOf('Canada') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="Canada"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="The United Kingdom"
                                  checked={target_country.indexOf('The United Kingdom') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="The United Kingdom"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="New Zealand"
                                  checked={target_country.indexOf('New Zealand') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="New Zealand"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="Ireland"
                                  checked={target_country.indexOf('Ireland') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="Ireland"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="The United States of America"
                                  checked={target_country.indexOf('The United States of America') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="The United States of America"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="I’m not sure"
                                  checked={target_country.indexOf('I’m not sure') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="I’m not sure"
                            />
                          </FormGroup>
                          </FormControl>
                          <br/>
                          Others:&nbsp;
                          <TextField
                            name="other_country"
                            value={other_country}
                            onChange={this.handleInput.bind(this)}
                            className="other-target-country"
                          />
                      </span>
                      <br/><br/>
                    </div>
                  :
                  <div>
                    <br/>
                    <span className="questions">
                      <Typography variant="headline" component="h6">
                        When do you plan to take the IELTS test?
                      </Typography>
                      <TextField
                        id="standard-select-currency-plan_exam_date"
                        select
                        name="plan_exam_date"
                        onChange={this.handleInput.bind(this)}
                        value={user.plan_exam_date}
                        error={user.plan_exam_date===""}
                        helperText="Planned exam date"
                        margin="normal"
                        required
                      >
                        {potentialDates.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </span><br/><br/><br/>
                    <span className="questions">
                      <Typography variant="headline" component="h6">
                        What is your target IELTS band score?
                      </Typography>
                      <TextField
                        id="standard-select-currency-p_score"
                        select
                        name="t_score"
                        onChange={this.handleInput.bind(this)}
                        value={user.t_score}
                        error={user.t_score===0}
                        helperText="IELTS band score"
                        margin="normal"
                        required
                      >
                        {bandScores.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </span><br/><br/><br/>
                    {
                      user.actual_country === "" || user.actual_country === "Invalid IP Address" || !user.actual_country?
                          <span className="questions">
                            <Typography variant="headline" component="h6">
                              Which country are you living in <b><u>NOW</u></b>?
                            </Typography>
                            <TextField
                              id="standard-select-currency"
                              select
                              name="country"
                              onChange={this.handleInput.bind(this)}
                              value={user.country}
                              error={user.country===""}
                              helperText="Please select your country"
                              margin="normal"
                              required
                            >
                              {country_list.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                            <br/><br/><br/>
                          </span>
                        :
                          null
                    }
                    <span className="questions">
                        <Typography variant="headline" component="h6">
                          {"If you're taking the IELTS exam for immigration, which country are you hoping to go – (select up to two options):"}
                        </Typography>
                        <FormControl component="fieldset" className={classes.formControl}>
                          <FormGroup>
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="Australia"
                                  checked={target_country.indexOf('Australia') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="Australia"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="Canada"
                                  checked={target_country.indexOf('Canada') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="Canada"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="The United Kingdom"
                                  checked={target_country.indexOf('The United Kingdom') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="The United Kingdom"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="New Zealand"
                                  checked={target_country.indexOf('New Zealand') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="New Zealand"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="Ireland"
                                  checked={target_country.indexOf('Ireland') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="Ireland"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="The United States of America"
                                  checked={target_country.indexOf('The United States of America') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="The United States of America"
                            />
                            <FormControlLabel
                              className="chkbox-answer"
                              control={
                                <Checkbox
                                  value="I’m not sure"
                                  checked={target_country.indexOf('I’m not sure') > -1}
                                  onClick={this.handleTargetCountryChange.bind(this)}
                                />
                              }
                              label="I’m not sure"
                            />
                          </FormGroup>
                        </FormControl>
                        <br/>
                        Others:&nbsp;
                        <TextField
                          name="other_country"
                          value={other_country}
                          onChange={this.handleInput.bind(this)}
                          className="other-target-country"
                        />
                      </span>
                    <br/><br/>
                </div>
                :
                null
              }
              {
                user.taken_exam===""?
                  null
                :
                  <Button
                    type="submit"
                    fullWidth
                    variant="raised"
                    color="primary"
                    className={classes.submit}
                    disabled={sendingRequest}
                  >
                    Take IELTS Mock Test
                  </Button>
              }

            </form>
          </Paper>
        </main>
        <ConfirmDialog {...this.props} {...this.state} />
        <WarningDialog {...this.props} {...this.state} />
      </React.Fragment>
    );
  }
}



Registration.propTypes = {
  classes : PropTypes.object.isRequired,
  user    : PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user   : state.registration.user,
    finish : state.loadingPage.currentIndex,
    done   : state.registration.user.done,
    target_country : state.registration.user.target_country,
    other_country  : state.registration.user.other_country,
    sendingRequest : state.app.sendingRequest,
    exam_set       : state.app.examSet,
    actual_country : state.registration.actual_country,
  }
};

export default connect(mapStateToProps)(withStyles(styles)(Registration));
