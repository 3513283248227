import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReadingGT from './ReadingGT';
import ReadingAC from './ReadingAC';

class Reading extends React.Component {
  render(){
    const { exam_type } = this.props;
    let component;
    if (exam_type === 'AC'){
      component = <ReadingAC/>;
    } else {
      component = <ReadingGT/>;
    }
    return component;
  }  
}

Reading.propTypes = {
  exam_type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    exam_type : state.registration.user.exam_type,
  }
};

export default connect(mapStateToProps)(Reading);