import { createStore, applyMiddleware } from 'redux';
import reducer from '../reducers';
import throttle from 'lodash/throttle';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import LogRocket from 'logrocket';
import { PERSISTED_STATE_KEY } from '../constants/keys';

LogRocket.init('uxplqp/ielts-mock-test');

// import setAuthorizationToken from '../utils/setAuthorizationToken'

let persistedState = {};
let defaultState = {};

if (sessionStorage.getItem(PERSISTED_STATE_KEY)){
  persistedState = JSON.parse(sessionStorage.getItem(PERSISTED_STATE_KEY)) ;
} else {
  persistedState = defaultState;
}

// if (persistedState!==defaultState){
//   setAuthorizationToken(persistedState.auth.access_token)
// }

const middleware = applyMiddleware(thunk, LogRocket.reduxMiddleware());

const store = createStore(
  reducer,
  persistedState,
  middleware,
);

store.subscribe(throttle(()=>{
  sessionStorage.setItem(PERSISTED_STATE_KEY, JSON.stringify(store.getState()));
}), 1000);

export default store;
