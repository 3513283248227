import axios from 'axios';
import { API, SERVER, IP_API } from '../../constants/urls';
import * as regActions from '../registration/registration';
import * as appActions from '../app/app';

export function wakeUp(user){
  return function(dispatch){
    axios.get(SERVER+'wake_up/')
      .then(function (response){
        dispatch(regActions.updateActualCountry(response.data.actual_country));
      })
      .catch(function (error){
        // alert('Encountered an error. Something went wrong.');
      });
  }
}

export function getExamSet(code){
  return function(dispatch){
    axios.get(SERVER+'get_exam_set?code=' + code)
      .then(function (response){
        dispatch(appActions.updateExamSet(parseInt(response.data.exam_set, 10)));
      })
      .catch(function (error){
        alert('Encountered an error. Something went wrong.');
      });
  }
}

export function getCountry(ip, user){
  return function(dispatch){
    axios.get(IP_API+ip)
      .then(function (response){
        user.actual_country = response.data.country;
        dispatch(regActions.updateUser(user));
      })
      .catch(function (error){
        alert('Encountered an error. Something went wrong.');
      });
  }
}

export function sendReadingAnswer(answers, name, email, user_id, exam_id, history){
  return function(dispatch){
    axios.post(
      API+"reading-answers/",
      {
        data    : answers,
        testee  : name,
        email   : email,
        test    : exam_id,
        user_id : user_id,
      }
    )
    .then(function (response){
      dispatch(appActions.updatePage(-1));
    })
    .catch(function (error){
      alert('Encountered an error. Something went wrong.');
    })
  }
}

export function sendWritingAnswer(answers, name, email, user_id){
  return function(dispatch){
    axios.post(
      API+"writing-answers/",
      {
        data    : answers,
        testee  : name,
        email   : email,
        user_id : user_id,
      }
    )
    .then(function (response){
      dispatch(appActions.updatePage(5));
    })
    .catch(function (error){
      alert('Encountered an error. Something went wrong.');
    })
  }
}

export function createUser(user, history){
  return function(dispatch){
    axios.post(
      API+"user/",
      user,
    )
    .then(function (response){
      user['id'] = response.data.id;
      user['sheet_id'] = response.data.sheet_id;
      dispatch(regActions.updateUser(user));
      dispatch(appActions.updateSendingRequest(false));
      dispatch(appActions.updatePage(-1));
    })
    .catch(function (error){
      alert(error);
    })
  }
}

export function uploadToDrive(user_id, history){
  return function(dispatch){
    axios.get(SERVER+"upload_to_drive?user_id="+user_id)
    .then(function (response){
      dispatch(appActions.updatePage(5));
    })
    .catch(function (error){
      alert(error);
    })
  }
}