import React from 'react';
import PropTypes from 'prop-types';
import Sound from 'react-sound';
import { connect } from 'react-redux';
import * as examActions from '../../actions/exam/listeningExam';

class Sounds extends React.Component {
  handleSongFinishedPlaying(){
    const { dispatch, currentIndex, maxPage, urls, examSet } = this.props;
    if (currentIndex < urls[examSet-1].length ){
      dispatch(examActions.updateAudioPosition(0));
      dispatch(examActions.updateCurrentAudioIndex(currentIndex+1));
      if (maxPage >= currentIndex+2){
        dispatch(examActions.updatePageNumber(currentIndex+2));
      }
    }
  }
  render() {
    const { dispatch, urls, currentIndex, audioPosition, maxPage, examSet } = this.props;
    return (
      <Sound
        url={urls[examSet - 1][currentIndex]}
        playStatus={currentIndex < maxPage? Sound.status.PLAYING:Sound.status.STOPPED}
        onPlaying={({ position }) => dispatch(examActions.updateAudioPosition(position))}
        onFinishedPlaying={this.handleSongFinishedPlaying.bind(this)}
        playFromPosition={audioPosition}
      />
    );
  }
}

Sounds.propTypes = {
  currentIndex: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    urls          : state.listeningExam.audioUrls,
    currentIndex  : state.listeningExam.currentIndex,
    maxPage       : state.listeningExam.maxPage,
    audioPosition : state.listeningExam.position,
    examSet       : state.app.examSet,
  }
};

export default connect(mapStateToProps)(Sounds);