import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WritingAC from './WritingAC';
import WritingGT from './WritingGT';

class Writing extends React.Component {
  render(){
    const { exam_type } = this.props;
    let component;
    if (exam_type === 'AC'){
      component = <WritingAC/>;
    } else {
      component = <WritingGT/>;
    }
    return component;
  }
}

Writing.propTypes = {
  exam_type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    exam_type : state.registration.user.exam_type,
  }
};

export default connect(mapStateToProps)(Writing);