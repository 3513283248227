import { UPDATE_USER, UPDATE_USER_ACTUAL_COUNTRY } from '../actions/types/registration';

const defaultState = {
  user : {
    name       : "",
    email      : "",
    id         : -1,
    l_score    : 0,
    r_score    : 0,
    w_score    : 0,
    s_score    : 0,
    o_score    : 0,
    t_score    : 0,
    p_score    : 0,
    country    : "",
    taken_exam : "",
    code       : "",
    prev_exam_date: "",
    plan_exam_date: "",
    done          : false,
    target_country: [],
    other_country : "",
    exam_type     : "GT",
  },
  actual_country: ""
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case UPDATE_USER: {
      return {...state, user : action.payload}
    }
    case UPDATE_USER_ACTUAL_COUNTRY: {
      return {...state, actual_country : action.payload}
    }
    default: return {...state};
  }
};