import * as actions from '../types/exam'

export const updatePageNumber = (number) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_READING_EXAM_PAGE_NUMBER,
      payload: number
    })
  };
};

export const setExamTime = (time) => {
  return dispatch => {
    dispatch({
      type: actions.SET_READING_EXAM_TIME,
      payload: time
    })
  };
};

export const updateAnswer = (answer, index) => {
  return dispatch => {
    dispatch({
      type   : actions.UPDATE_READING_EXAM_ANSWER,
      answer : answer,
      index  : index,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type   : actions.RESET_EXAM_SETTINGS,
    });
  };
}