import React from 'react';
import { connect } from 'react-redux';
import { ReactMic } from 'react-mic';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import platform from 'platform';
import Button from '@material-ui/core/Button';
import ReactAudioPlayer from 'react-audio-player';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import Snackbar from '@material-ui/core/Snackbar';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffRounded from '@material-ui/icons/HighlightOffRounded';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import {
  isMobile,
  isChrome,
  isFirefox,
  browserVersion,
  osName,
  browserName,
  osVersion,
} from 'react-device-detect';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%",
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    textAlign: 'left'
  },
  textCenter:{
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing.unit,
  },
  recording: {
    marginLeft:   theme.spacing.unit * 3,
    marginRight:  theme.spacing.unit * 3,
    marginTop:    theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recording: false,
      paused: false,
      height: 0,
      width: 0,
      browser: {
        name:"",
        version:""
      },
      os:"",
      audioSrc: "",
      count: 1,
      timer: 0,
      started: false,
      showWave: false,
    }
  }

  errorMessage(){
    alert("Please allow this app to use your microphone.");
  }

  checkMicrophone(){
    try {
      navigator.getUserMedia = (navigator.getUserMedia({audio : true}, this.startRecording.bind(this), this.errorMessage.bind(this)));
      return;
    } catch (e){}
    try {
      navigator.webkitGetUserMedia({audio : true}, this.startRecording.bind(this), this.errorMessage.bind(this));
      return;
    } catch (e){}
    try {
      navigator.mozGetUserMedia({audio : true}, this.startRecording.bind(this), this.errorMessage.bind(this));
      return;
    } catch (e){}
    try {
      navigator.msGetUserMedia({audio : true}, this.startRecording.bind(this), this.errorMessage.bind(this));
      return;
    } catch (e){}
  }

  startRecording = () => {
    const { paused, recording } = this.state
    if (paused) {
      this.setState({ isPaused: false })
    } else if(recording) {
      this.setState({ isPaused: true })
    } else {
      this.setState({ showWave: true });
      this.setState({ recording: true, started:true })
      this.timer = setInterval(this.tick.bind(this), 1000);
    }
  }

  stopRecording = () => {
    clearInterval(this.timer);
    this.timer = null;
    this.setState({
      recording: false,
      count:this.state.count +1,
    });
  }

  onData(recordedBlob) {
  }

  onStop(recordedBlob) {
    this.setState({audioSrc:recordedBlob.blobURL, showWave:false, timer:0});
    clearInterval(this.timer);
    this.timer = null;
  }

  resetStart(){
    this.setState({started:false});
  }

  componentDidMount(){
    this.setState({
      height: window.screen.availHeight,
      width: window.screen.availWidth,
      browser: {
        name: platform.name,
        version: platform.version,
      },
      os: platform.os,
    });
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }

  get_browser() {
    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name:'IE',version:(tem[1]||'')};
        }
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR|Edge\/(\d+)/)
        if(tem!=null)   {return {name:'Opera', version:tem[1]};}
        }
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    return {
      name: M[0],
      version: M[1]
    };
  }

  finishAnswer(){
    this.doneAnswering();
  }

  tick(){
    const { timer } = this.state;
    const newTime = timer + 1;
    if (newTime <= 5){
      this.setState({timer:newTime});
    } else {
      clearInterval(this.timer);
      this.timer = null;
      this.setState({timer:0, recording:false});
    }
  }

  render() {
    const { classes } = this.props;
    const { height, width, audioSrc } = this.state;

    let browserAllowed = true;
    if (!isChrome && !isFirefox){
      browserAllowed = false;
    }

    if (isChrome){
      browserAllowed = browserVersion > "50.0";
    }

    if (isFirefox){
      browserAllowed = browserVersion > "30.0";
    }

    let osAllowed = true;
    if (isMobile){
      osAllowed = false;
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
          <Grid container spacing={16}>
            <Grid item md={6}>
              <div className="requirement-check">
                <Grid container spacing={16}>
                  <Grid item md={10}>
                    <Typography variant="subheading"  className="text-white">
                      Javascript is enabled
                    </Typography>
                  </Grid>
                  <Grid item md={2} className="text-right icon">
                    <CheckCircleIcon className="icon"/>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="requirement-check">
                <Grid container spacing={16}>
                  <Grid item md={10}>
                    <Typography variant="subheading" className="text-white">
                      {"Screen Resolution is "+ width +" x " + height}
                    </Typography>
                  </Grid>
                  <Grid item md={2} className="text-right icon">
                    <CheckCircleIcon className="icon"/>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={osAllowed?"requirement-check":"requirement-missing"}>
                <Grid container spacing={16}>
                  <Grid item md={10}>
                    <Typography variant="subheading" className="text-white">
                      {"Operating System is " + osName + " " + osVersion }
                    </Typography>
                  </Grid>
                  <Grid item md={2} className="text-right icon">
                    {
                      osAllowed?
                        <CheckCircleIcon className="icon"/>
                        :
                        <HighlightOffRounded className="icon-wrong"/>
                    }
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={browserAllowed?"requirement-check":"requirement-missing"}>
                <Grid container spacing={16}>
                  <Grid item md={10}>
                    <Typography variant="subheading" className="text-white">
                      {"Browser is " + browserName + " " + browserVersion}
                    </Typography>
                  </Grid>
                  <Grid item md={2} className="text-right icon">
                    {
                      browserAllowed?
                        <CheckCircleIcon className="icon"/>
                        :
                        <HighlightOffRounded className="icon-wrong"/>
                    }
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={12} className="text-center">
            {
              this.state.showWave?
                <ReactMic
                  audioBitsPerSecond= {128000}
                  record={this.state.recording}
                  className="sound-wave"
                  onStop={this.onStop.bind(this)}
                  strokeColor="#000000"
                  backgroundColor="#FFFFFF" />
                :
                  null
            }
            </Grid>
            <Grid item md={12} className="text-center">
              <ReactAudioPlayer
                src={audioSrc}
                controls
              />
            </Grid>
            <Grid item md={12} className="text-center">
              <Button
                className="little-margin"
                color="secondary"
                variant="fab"
                onClick={this.checkMicrophone.bind(this)}
                type="button"
                disabled={this.state.recording}
              >
                <MicIcon />
              </Button>
              <Button
                className="little-margin"
                color="secondary"
                variant="fab"
                onClick={this.stopRecording.bind(this)}
                type="button"
                disabled={!this.state.recording}
              >
                <StopIcon/>
              </Button>
            </Grid>
            <Grid item md={12} className="text-center">
              <div className="center-box-medium">
                <Typography variant="headline" component="h6">
                  {"For the Listening and the Speaking Test to take place properly, you need to see if your Microphone and Speakers/Earphones work properly."}
                </Typography>
                <br/>
                <Typography variant="headline" component="h6">
                  {"Click the red microphone button above and speak into your Microphone for a few seconds."}
                </Typography>
                <br/>
                <Typography variant="headline" component="h6">
                  {"If you can hear your voice clearly, then everything should be OK!"}
                </Typography>
              </div>
            </Grid>
          </Grid>
          </Paper>
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.recording}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="error"
            message="Speak into your microphone now for the next few seconds."
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.started && !this.state.recording}
          onClose={this.resetStart.bind(this)}
          autoHideDuration={8000}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message="Your recording has ended. Click the Play button to hear what you said."
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // currentIndex : state.speakingExam.currentIndex,
    // playingAudio : state.speakingExam.playingAudio,
    // timer : state.speakingExam.timer,
    // recording : state.speakingExam.recording,
    // recIndex : state.speakingExam.recordingIndex,
    // recordingDurations : state.speakingExam.recordingDurations,
    // timer2 : state.speakingExam.timer2,
    // timer2Status : state.speakingExam.timer2Status,
  }
};

export default connect(mapStateToProps)(withStyles(styles)(App));