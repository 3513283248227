import * as actions from '../actions/types/app';
const defaultState = {
  currentPage    : 0,
  examSet        : 1,
  sendingRequest : false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_SENDING_REQUEST: {
      return {...state, sendingRequest: action.payload};
    }
    case actions.UPDATE_CURRENT_PAGE: {
      return {...state, currentPage: action.payload};
    }
    case actions.UPDATE_EXAM_SET: {
      return {...state, examSet: action.payload};
    }
    default: return {...state};
  }
};