import * as actions from '../actions/types/exam';
import { updateObjectInArray } from '../utils/updateObjectInArray';

const defaultState = {
  pageNumber : 1,
  maxPageAC  : 3,
  maxPageGT  : 5,
  examTime   : 3600,
  answers    : new Array(40).join(".").split("."),
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_READING_EXAM_PAGE_NUMBER: {
      return {...state, pageNumber: action.payload};
    }
    case actions.SET_READING_EXAM_TIME: {
      return {...state, examTime: action.payload};
    }
    case actions.UPDATE_READING_EXAM_ANSWER: {
      return {...state, answers: updateObjectInArray(state.answers, action)};
    }
    case actions.RESET_EXAM_SETTINGS: {
      return {...defaultState};
    }
    default: return {...state};
  }
};