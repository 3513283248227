import React from "react";
import { connect } from "react-redux";
import { ReactMic } from "react-mic";
import SpeakingSound from "../others/SpeakingSound";
import MicIcon from "@material-ui/icons/MicTwoTone";
import MicOffIcon from "@material-ui/icons/MicOffTwoTone";
import PersonIcon from "@material-ui/icons/RecordVoiceOver";
import PersonIconOff from "@material-ui/icons/VoiceOverOff";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import * as actions from "../../actions/exam/speakingExam";
import { updatePage } from "../../actions/app/app";
import { updateUser } from "../../actions/registration/registration";
import { updateStartStatus } from "../../actions/exam/speakingExam";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppCheck from "../others/MicTest";

// Load the required clients and packages
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const { S3Client, PutObjectCommand } = require("@aws-sdk/client-s3");

// Set the AWS Region
const REGION = "ap-southeast-1";

// Initialize the Amazon Cognito credentials provider
const s3 = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: "ap-southeast-1:30545b5e-b245-4f26-ab2c-5a3c46177b03",
  }),
});

const bucketName = "ieltsmocktestonline";

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "65%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paperTopLeft: {
    padding: `${theme.spacing.unit}px 20px ${theme.spacing.unit}px 20px`,
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  button: {
    margin: theme.spacing.unit,
  },
  recording: {
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
});

class Speaking extends React.Component {
  onData(recordedBlob) {
    // console.log('chunk of real-time data is: ', recordedBlob);
  }

  onStop = (recordedBlob) => {
    const { currentIndex, recIndex, dispatch, user } = this.props;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", recordedBlob.blobURL);
    xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      const userName = user.sheet_id;
      const file = new File([xhr.response], "test.webm"); //xhr.response is now a blob object

      const uploadParams = {
        Bucket: bucketName,
        Key:
          "04 Speaking Test/" +
          userName +
          "/" +
          userName +
          " " +
          this.padZero(recIndex + 1) +
          ".webm",
        Body: file,
        ACL: "public-read",
        ContentType: "audio/webm",
      };
      (async () => {
        try {
          await s3.send(new PutObjectCommand(uploadParams));
          dispatch(actions.updateRecordingIndex(recIndex + 1));
          dispatch(actions.updateAudioPosition(0));
          dispatch(actions.updateCurrentAudioIndex(currentIndex + 1));
          dispatch(actions.updateAudioPlaying(true));
          if (recIndex === 18) {
            const newUser = { ...user };
            newUser.done = true;
            dispatch(updateUser(newUser));
          }
        } catch (err) {
          return alert(
            "There was an error uploading your audio: " +
              err +
              "\n\n Please refresh the page multiple times until the audio plays again."
          );
        }
      })();
    };
    xhr.send();
  };

  startPlaying() {
    const { dispatch, currentIndex } = this.props;
    dispatch(actions.updateAudioPosition(0));
    dispatch(actions.updateCurrentAudioIndex(currentIndex + 1));
    dispatch(actions.updateAudioPlaying(true));
  }

  repeatAudio() {
    const { dispatch, currentIndex } = this.props;
    dispatch(actions.updateAudioPosition(0));
    dispatch(actions.updateCurrentAudioIndex(currentIndex));
    dispatch(actions.updateAudioPlaying(true));
  }

  tick() {
    let { timer, dispatch, recIndex, recordingDurations } = this.props;
    const newTimer = timer + 1;
    dispatch(actions.updateTimer(newTimer));
    if (newTimer >= recordingDurations[recIndex]) {
      this.doneAnswering();
    }
  }

  tick2() {
    const { dispatch, timer2, currentIndex } = this.props;
    let newTimer = timer2 - 1;
    if (newTimer > 0) {
      dispatch(actions.updateTimer2(newTimer));
    } else {
      clearInterval(this.timer);
      this.timer = null;
      dispatch(actions.updateCurrentAudioIndex(currentIndex + 1));
      dispatch(actions.updateAudioPlaying(true));
      dispatch(actions.updateAudioPosition(0));
      dispatch(actions.updateRecordingStatus(false));
      dispatch(actions.updatePart2Status(false));
    }
  }

  doneAnswering() {
    const { dispatch } = this.props;
    this.resetTimer();
    dispatch(actions.updateAudioPlaying(false));
    dispatch(actions.updateRecordingStatus(false));
  }

  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }

  startTimer2() {
    this.timer = setInterval(this.tick2.bind(this), 1000);
  }

  padZero(num) {
    var zero = 2 - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  resetTimer() {
    clearInterval(this.timer);
    this.timer = null;
    const { dispatch } = this.props;
    dispatch(actions.updateTimer(0));
  }

  skipExam = () => {
    const { dispatch, user } = this.props;
    const newUser = { ...user };
    newUser.done = true;
    dispatch(updateUser(newUser));
    dispatch(updatePage(6));
  };

  componentDidMount() {
    const { dispatch, start } = this.props;
    if (start) {
      dispatch(actions.updateRecordingStatus(false));
      dispatch(actions.updateAudioPosition(0));
      dispatch(actions.updateShowResume(true));
      dispatch(actions.updateTimer2(60));
      dispatch(actions.updatePart2Status(false));
    }
  }

  componentDidUpdate(prevProps) {
    const { recording, timer2Status } = this.props;
    if (recording && recording !== prevProps.recording) {
      this.startTimer();
    }
    if (!recording && recording !== prevProps.recording) {
      this.resetTimer();
    }
    if (timer2Status && !prevProps.timer2Status) {
      this.startTimer2();
    }
  }

  finishAnswer() {
    this.doneAnswering();
  }

  handleBtnChange(e) {
    const { dispatch } = this.props;
    const skip = e.target.value === "skip";
    dispatch(actions.updateSkipExam(skip));
  }

  handleBtnClick() {
    const { dispatch, skipExam } = this.props;
    if (skipExam) {
      this.skipExam();
    } else {
      dispatch(updateStartStatus(true));
      dispatch(actions.updateAudioPlaying(true));
    }
  }

  resume() {
    const { dispatch } = this.props;
    dispatch(actions.updateShowResume(false));
    dispatch(actions.updateAudioPlaying(true));
  }

  render() {
    const {
      classes,
      done,
      dispatch,
      playingAudio,
      recIndex,
      recording,
      start,
      timer,
      timer2,
      timer2Status,
      skipExam,
      showResume,
    } = this.props;
    const examMin = Math.floor(timer / 60);
    const examSec = timer - examMin * 60;

    if (done) {
      dispatch(updatePage(6));
    }

    const action = (
      <Button
        key="snackbar-skip"
        variant="contained"
        color="secondary"
        onClick={this.skipExam.bind(this)}
        size="small"
      >
        {
          "Finish Speaking Test Early And Proceed To Instructions To Get Results"
        }
      </Button>
    );

    return (
      <React.Fragment>
        <CssBaseline />
        {showResume ? (
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <div className="text-center">
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  className="full-width"
                  onClick={this.resume.bind(this)}
                >
                  {"Resume with the speaking test"}
                </Button>
              </div>
            </Paper>
          </main>
        ) : !start ? (
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <div className="center-div text-center eighty-percent">
                <Typography component="p">
                  {
                    "Before you proceed, scroll down and 1) read the information about the Speaking Test below and 2) do the microphone check once again. After that, select one of these two options to continue:"
                  }
                </Typography>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup aria-label="gender" name="gender2">
                    <FormControlLabel
                      value="continue"
                      onClick={this.handleBtnChange.bind(this)}
                      control={<Radio color="secondary" checked={!skipExam} />}
                      label="My microphone is ready and I want to start the Speaking Test now!"
                    />
                    <FormControlLabel
                      value="skip"
                      onClick={this.handleBtnChange.bind(this)}
                      control={<Radio color="secondary" checked={skipExam} />}
                      label="My microphone is NOT working and I want to skip the Speaking Test and proceed to the instructions to get my Listening, Reading and Writing Test results"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                <br />
                <div className="text-center">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    className="full-width"
                    onClick={this.handleBtnClick.bind(this)}
                  >
                    {"Proceed"}
                  </Button>
                </div>
                <br />
                <hr className="speaking" speaking />
                <br />
                <Typography className="q-content">
                  <b>{"IELTS SPEAKING"}</b>
                  {"\n\nTime: Approximately 10 minutes\n\n"}
                  <b>{"NOTE ABOUT THE SPEAKING TEST"}</b>
                  {
                    "\n\nThe actual Speaking Test in the IELTS exam will be separate from the Listening, Reading and Writing Test you just finished. It would be in a room with a human speaking examiner.\n\nWhat you're going to experience now on the computer over the next 10 minutes or so is a computerized version which is meant to simulate what you're going to experience during the actual Speaking Test. Just imagine the questions are asked by an actual human examiner sitting in front of you."
                  }
                  <b>{"\n\nINSTRUCTIONS TO CANDIDATES"}</b>
                  <ul>
                    <li>
                      Answer <b>all</b> the questions as best as you can.
                    </li>
                    <li>
                      For <b>Parts 1</b> and <b>3</b>, questions will be asked{" "}
                      <b>immediately</b>. After every question is asked, your
                      microphone will be <b>recorded immediately</b> (You will
                      see the words “Recording your answer”) so speak into your
                      microphone. When you've finished answering that question,
                      just click "<b>FINISH</b>" and it'll go to the next
                      question. You can also click "<b>REPEAT QUESTION</b>" if
                      you want the question to be repeated.
                    </li>
                    <li>
                      For <b>Part 2</b>, you will be given a long question. You
                      have 1 minute to prepare your answer (You can also write
                      any notes during that 1 minutes on a piece of paper).
                      After the 1 minute, you will have to speak for 1 to 2
                      minutes. You can look at your notes if you want to.
                    </li>
                  </ul>
                  <b>
                    {
                      "DETAILED INFORMATION ABOUT WHAT TO EXPECT IN THE SPEAKING TEST"
                    }
                  </b>
                  <ul>
                    <li>
                      There are 3 Parts to the Speaking Test and some
                      Administrative Questions before Part 1 begins:
                      <ul>
                        <li>
                          <b>Administrative Questions:</b> You will be asked
                          about 3 questions about yourself. This is to just get
                          information about yourself and you will not be
                          evaluated.
                        </li>
                        <li>
                          <b>Part 1:</b> The examiner will ask you about 9
                          questions taken from 3 different topical areas (3
                          questions per topic).
                        </li>
                        <li>
                          <b>Part 2:</b> The examiner will give you a long
                          question and also 1 minute to prepare your answer. You
                          can get ready a piece of paper and write any notes you
                          want. After the 1 minute, you will have to speak for 1
                          to 2 minutes and you can look at your notes.
                        </li>
                        <li>
                          <b>Part 3:</b> The examiner will ask you about 6
                          questions in total taken from 2 closely related topics
                          (3 questions per topic).
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Typography>
              </div>
            </Paper>
            <AppCheck />
            <br />
            <br />
            <br />
          </main>
        ) : (
          <main className={classes.layout}>
            {timer2Status ? (
              <Paper className={classes.paper}>
                <div className="center-div">
                  <Typography
                    className="text-center"
                    variant="headline"
                    component="h6"
                  >
                    {"Time remaining: "} <b>{timer2}</b> {" seconds remaining"}
                  </Typography>
                  <Typography variant="headline" component="h6">
                    <br />
                    <br />
                    {
                      "Describe an important conversation that you had with someone."
                    }
                    <br />
                    <br />
                    {"You should say:"}
                    <ul>
                      <li>when you had this conversation</li>
                      <li>who you had the conversation with</li>
                      <li>what the conversation was about</li>
                    </ul>
                    {"and explain why this conversation was important."}
                  </Typography>
                </div>
                <div className="left-examtype">
                  <Paper className={classes.paperTopLeft}>
                    <Typography
                      className="text-center"
                      variant="headline"
                      component="h6"
                    >
                      {"Part 2"}
                    </Typography>
                  </Paper>
                </div>
              </Paper>
            ) : (
              <Paper className={classes.paper}>
                <Typography
                  variant="headline"
                  component="h5"
                  className={classes.textCenter}
                >
                  {"Speaking Test"}
                </Typography>
                <br />
                <br />
                <div className="text-center">
                  {playingAudio ? (
                    <PersonIcon color="primary" style={{ fontSize: "10em" }} />
                  ) : (
                    <PersonIconOff
                      color="primary"
                      style={{ fontSize: "10em" }}
                    />
                  )}
                  <Typography variant="headline" component="h6">
                    {"Interviewer"}
                  </Typography>
                </div>
                <br />
                <br />
                <div className="text-center">
                  <div className="padding-speaking">
                    <Typography variant="headline" component="h6">
                      {!recording
                        ? "Please listen to the audio and prepare your response"
                        : "Recording your answer"}
                    </Typography>
                  </div>
                  <div className="padding-speaking">
                    {!recording ? (
                      <MicOffIcon color="primary" style={{ fontSize: "3em" }} />
                    ) : (
                      <MicIcon color="primary" style={{ fontSize: "3em" }} />
                    )}
                  </div>
                  <div className="padding-speaking">
                    <Typography variant="headline" component="h6">
                      {this.padZero(examMin) + ":" + this.padZero(examSec)}
                    </Typography>
                  </div>
                  <div className="padding-speaking">
                    <ReactMic
                      record={recording && start}
                      className="sound-wave"
                      onStop={this.onStop.bind(this)}
                      onData={this.onData.bind(this)}
                      strokeColor="#ffffff"
                      backgroundColor="#3f51b5"
                    />
                  </div>
                  <div className="padding-speaking">
                    <Button
                      onClick={this.finishAnswer.bind(this)}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      disabled={!recording}
                    >
                      FINISH
                    </Button>
                    <Button
                      onClick={this.repeatAudio.bind(this)}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      disabled={!recording || recIndex === 12}
                    >
                      REPEAT QUESTION
                    </Button>
                  </div>
                  {recIndex === 12 && recording ? (
                    <div className="padding-speaking">
                      <Typography variant="headline" component="h6">
                        {"You should speak for 1 to 2 minutes"}
                      </Typography>
                      <Typography variant="headline" component="h6">
                        {"[Scroll down to see the questions]"}
                      </Typography>
                      <br />
                      <br />
                    </div>
                  ) : null}
                </div>
                {recIndex === 12 && recording ? (
                  <div className="center-div bordered">
                    <Typography variant="headline" component="h6">
                      {
                        "Describe an important conversation that you had with someone."
                      }
                      <br />
                      <br />
                      {"You should say:"}
                      <ul>
                        <li>when you had this conversation</li>
                        <li>who you had the conversation with</li>
                        <li>what the conversation was about</li>
                      </ul>
                      {"and explain why this conversation was important."}
                    </Typography>
                  </div>
                ) : null}
                <div className="snackbar-btn">{action}</div>
                <div className="left-examtype">
                  <Paper className={classes.paperTopLeft}>
                    <Typography
                      className="text-center"
                      variant="headline"
                      component="h6"
                    >
                      {recIndex < 3
                        ? "Administrative Questions"
                        : recIndex < 12
                        ? "Part 1"
                        : recIndex === 12
                        ? "Part 2"
                        : "Part 3"}
                    </Typography>
                  </Paper>
                </div>
              </Paper>
            )}
            <SpeakingSound />
          </main>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentIndex: state.speakingExam.currentIndex,
    playingAudio: state.speakingExam.playingAudio,
    timer: state.speakingExam.timer,
    recording: state.speakingExam.recording,
    recIndex: state.speakingExam.recordingIndex,
    recordingDurations: state.speakingExam.recordingDurations,
    timer2: state.speakingExam.timer2,
    timer2Status: state.speakingExam.timer2Status,
    done: state.loadingPage.done,
    audioPosition: state.speakingExam.position,
    user: state.registration.user,
    start: state.speakingExam.startExam,
    skipExam: state.speakingExam.skipExam,
    showResume: state.speakingExam.showResume,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Speaking));
