import * as actions from '../types/exam'

export const updateCurrentAudioIndex = (index) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_SPEAKING_EXAM_CURRENT_AUDIO_INDEX,
      payload : index,
    });
  };
};

export const updateAudioPosition = (position) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_SPEAKING_EXAM_AUDIO_POSITION,
      payload : position,
    });
  };
};

export const updateAudioPlaying = (playAudio) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_SPEAKING_EXAM_AUDIO_PLAYING_STATUS,
      payload : playAudio,
    });
  };
};

export const updateRecordingStatus = (recording) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_SPEAKING_EXAM_RECORDING_STATUS,
      payload : recording,
    });
  };
};

export const updateTimer = (timer) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_SPEAKING_EXAM_TIMER,
      payload: timer,
    });
  };
};

export const updateTimer2 = (timer) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_SPEAKING_EXAM_TIMER2,
      payload: timer,
    });
  };
};

export const updateRecordingIndex = (index) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_SPEAKING_EXAM_RECORDING_INDEX,
      payload: index,
    });
  };
};

export const updatePart2Status = (status) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_SPEAKING_EXAM_PART2_STATUS,
      payload: status,
    });
  };
};

export const updateStartStatus = (status) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_SPEAKING_EXAM_START_STATUS,
      payload: status,
    });
  };
};

export const updateSkipExam = (skip) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_SPEAKING_EXAM_SKIP_EXAM,
      payload: skip,
    });
  };
};

export const updateShowResume = (showResume) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_SPEAKING_EXAM_SHOW_RESUME,
      payload: showResume,
    });
  };
};