import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from '../../actions/utils/loadingPage';
import { updatePage } from '../../actions/app/app';
import Button from '@material-ui/core/Button';


import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: "#2196f3",
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <span>Please take note that if your screen is not big enough, you may need to use the <b>scroll bar on the right to scroll up and down</b> to see everything during this test</span>
        </span>
      }
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);


const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%",
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    textAlign: 'left'
  },
  textCenter:{
    textAlign: 'center',
  },
  blink:{
    backgroundColor : 'red',
    animation       : "blinker 1s linear infinite",
    color           : 'white',
  },
});

class Loading extends React.Component{
  tick(){
    const {
      dispatch,
      loadTime,
      currentIndex,
      nextPages,
    } = this.props;
    const newTime = loadTime-1;
    if (newTime > 0){
      dispatch(actions.setLoadTime(newTime));
    } else {
      dispatch(actions.setLoadTime(120));
      dispatch(actions.updateCurrentIndex(currentIndex+1));
      dispatch(updatePage(nextPages[currentIndex]));
    }
  }

  skipExam = () => {
    const { dispatch, nextPages, currentIndex } = this.props
    dispatch(actions.setLoadTime(120));
    dispatch(actions.updateCurrentIndex(currentIndex+1));
    dispatch(updatePage(nextPages[currentIndex]));
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }
  componentDidMount(){
    this.timer = setInterval(this.tick.bind(this), 1000);
    const urlParams = new URLSearchParams(window.location.search);
    const timer = urlParams.get('change_timer');
    const { dispatch } = this.props;
    if (timer){
      dispatch(actions.setLoadTime(parseInt(timer, 10)));
    }
  }
  render() {
    const {
      classes,
      messages,
      currentIndex,
      loadTime,
      dispatch,
      done,
      user,
      btnMessages,
      nextPages,
    } = this.props;
    if (done){
      dispatch(updatePage(5));
    }
    const minutes = Math.floor(loadTime / 60);
    const seconds = loadTime - minutes * 60;
    const action = (
      <Button
        className="width-60"
        key="snackbar-skip"
        variant="contained"
        color="secondary"
        onClick={this.skipExam.bind(this)}
        size="small"
      >
        {btnMessages[currentIndex]}
      </Button>
    );
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="headline" component="h6" className={classes.textCenter}>
              {messages[currentIndex]}
            </Typography>
            <br/>
            <Typography variant="headline" component="h6" className={classes.textCenter}>
              <CircularProgress className={classes.progress} color="secondary" />
            </Typography>
            <br/>
            <Typography variant="headline" component="h6" className={classes.textCenter}>
              {" Time Remaining: " + minutes + "m " + seconds + "s"}
            </Typography>
            <br/>
            <div className="text-center">
              { action }
            </div>
            {
              currentIndex === 0?
                <div>
                  <br/><br/>
                  <div className="center-box-loading text-center">
                    <Typography component="h6">
                      <b>{user.name}</b> take note of your ID: <b className={minutes===1 && (seconds>=30 && seconds<=60)? classes.blink:""}>&nbsp;{user.sheet_id}&nbsp;</b>. You should write it down somewhere now before you start the test.
                    </Typography>
                    <Typography component="h6">
                      <u><b>You will need this ID to retrieve your results after your test is marked.</b></u>
                    </Typography>
                  </div>
                  <br/><br/>
                  <Typography variant="headline" component="h5">
                    IELTS LISTENING
                  </Typography>
                  <br/>
                  <Typography component="h6">
                    Time: Approximately 30 minutes
                  </Typography>
                  <br/>
                  <Typography variant="headline" component="h5">
                    INSTRUCTIONS TO CANDIDATES
                  </Typography>
                  <Typography component="h6">
                    <ul>
                      <li>Answer <b>all</b> the questions.</li>
                      <li>You can change your answers at any time during the test.</li>
                    </ul>
                  </Typography>
                  <Typography variant="headline" component="h5">
                    INFORMATION FOR CANDIDATES
                  </Typography>
                  <Typography component="h6">
                    <ul>
                      <li>There are 40 questions in this test.</li>
                      <li>Each question carries one mark.</li>
                      <li>There are four sections to the test – 10 questions per section.</li>
                      <li>You will hear each section only once.</li>
                      <li>You ought to write down your answers as you listen to the recording</li>
                      <li>For each section of the test there will be time for you to look through the questions and time for you to check your answers.</li>
                      <li>At the end of the four sections, you will be given 2 minutes to check all your answers before the test moves on to the Reading Test.</li>
                    </ul>
                  </Typography>
                </div>
              :
              currentIndex === 1?
                <div>
                  <br/><br/>
                  <Typography variant="headline" component="h5">
                    IELTS READING
                  </Typography>
                  <br/>
                  <Typography component="h6">
                    Time: 1 hour
                  </Typography>
                  <br/>
                  <Typography variant="headline" component="h5">
                    INSTRUCTIONS TO CANDIDATES
                  </Typography>
                  <Typography component="h6">
                    <ul>
                      <li>Answer <b>all</b> the questions.</li>
                      <li>You can change your answers at any time during the test.</li>
                    </ul>
                  </Typography>
                  <Typography variant="headline" component="h5">
                    INFORMATION FOR CANDIDATES
                  </Typography>
                  <Typography component="h6">
                    <ul>
                      <li>There are 40 questions in this test.</li>
                      <li>Each question carries one mark.</li>
                      <li>There are three parts to the test.</li>
                      <li>The test clock will show you when there are 10 minutes and 5 minutes remaining.</li>
                    </ul>
                  </Typography>
                </div>
              :
              currentIndex === 2?
                <div>
                  <br/><br/>
                  <Typography variant="headline" component="h5">
                    IELTS WRITING
                  </Typography>
                  <br/>
                  <Typography component="h6">
                    Time: 1 hour
                  </Typography>
                  <br/>
                  <Typography variant="headline" component="h5">
                    INSTRUCTIONS TO CANDIDATES
                  </Typography>
                  <Typography component="h6">
                    <ul>
                      <li>Answer <b>both</b> parts.</li>
                      <li>You can change your answers at any time during the test.</li>
                    </ul>
                  </Typography>
                  <Typography variant="headline" component="h5">
                    INFORMATION FOR CANDIDATES
                  </Typography>
                  <Typography component="h6">
                    <ul>
                      <li>There are 2 parts in this test.</li>
                      <li>Part 2 contributes twice as much as Part 1 to the writing score</li>
                      <li>The test clock will show you when there are 10 minutes and 5 minutes remaining.</li>
                    </ul>
                  </Typography>
                </div>
              :
                null
            }
          </Paper>
        </main>
        {
          nextPages[currentIndex] === 2?
            <div className="snackbar-info">
              <MySnackbarContentWrapper
                variant="info"
                className={classes.margin}
              />
            </div>
          :
            null
        }
      </React.Fragment>
    );
  }
}

Loading.propTypes = {
  classes  : PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loadTime     : state.loadingPage.loadTime,
    messages     : state.loadingPage.messages,
    btnMessages  : state.loadingPage.btnMessages,
    currentIndex : state.loadingPage.currentIndex,
    nextPages    : state.loadingPage.nextPages,
    done         : state.registration.user.done,
    currentPage  : state.app.currentPage,
    user         : state.registration.user,
  }
};

export default connect(mapStateToProps)(withStyles(styles)(Loading));