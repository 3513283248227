import React from 'react';
import PropTypes from 'prop-types';
import Sound from 'react-sound';
import { connect } from 'react-redux';
import * as examActions from '../../actions/exam/speakingExam';
import { updatePage } from '../../actions/app/app';

class SpeakingSounds extends React.Component {
  handleSongFinishedPlaying(){
    const { dispatch, currentIndex, urls } = this.props;
    if (currentIndex === 12){
      dispatch(examActions.updatePart2Status(true));
      return;
    }
    if (currentIndex < urls.length - 1 ){
      dispatch(examActions.updateAudioPlaying(false));
      dispatch(examActions.updateRecordingStatus(true))
    } else {
      dispatch(updatePage(6));
    }
  }
  render() {
    const { dispatch, urls, currentIndex, audioPosition, playingAudio } = this.props;
    return (
      <Sound
        url={urls[currentIndex]}
        playStatus={currentIndex < urls.length && playingAudio? Sound.status.PLAYING:Sound.status.STOPPED}
        onPlaying={({ position }) => dispatch(examActions.updateAudioPosition(position))}
        onFinishedPlaying={this.handleSongFinishedPlaying.bind(this)}
        playFromPosition={audioPosition}
      />
    );
  }
}

SpeakingSounds.propTypes = {
  currentIndex: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    urls          : state.speakingExam.audioUrls,
    currentIndex  : state.speakingExam.currentIndex,
    audioPosition : state.speakingExam.position,
    playingAudio  : state.speakingExam.playingAudio,
    recording     : state.speakingExam.recording,
  }
};

export default connect(mapStateToProps)(SpeakingSounds);