import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";

import { wakeUp } from "../../actions/api/requests";
import { updateUser } from "../../actions/registration/registration";
import { updatePage } from "../../actions/app/app";

import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "400px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    marginTop: theme.spacing.unit * 3,
  },
  paper: {
    marginTop: theme.spacing.unit,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  layoutTwo: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "800px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paperTwo: {
    marginTop: theme.spacing.unit * 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Registration extends React.Component {
  componentDidMount() {
    try {
      window.scrollTo(0, 0);
    } catch (e) {}
    const { dispatch, user } = this.props;
    dispatch(wakeUp(user));
  }
  handleInput = (e) => {
    const { dispatch, user } = this.props;
    const newUser = { ...user };
    newUser[e.target.name] = e.target.value;
    dispatch(updateUser(newUser));
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(updatePage(1));
  };
  render() {
    const { classes, user, done, dispatch } = this.props;
    if (done) {
      dispatch(updatePage(5));
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layoutTwo}>
          <Paper className={classes.paperTwo}>
            <Typography variant="headline" component="h6" align="center">
              {
                "If you have not read the instructions for the mock test, please click "
              }
              <a
                href="http://www.ieltsmocktestonline.com/instructions/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Mock Test Instructions
              </a>
              {
                " now and read the page carefully so your test will be successful."
              }
            </Typography>
          </Paper>
        </main>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="headline" align="center">
              Before you take the test, please let us know your name and email.
            </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="full_name">First Name</InputLabel>
                <Input
                  name="name"
                  type="text"
                  id="full_name"
                  autoComplete="first_name"
                  autoFocus
                  value={user.name}
                  onChange={this.handleInput.bind(this)}
                  required
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  type="email"
                  onChange={this.handleInput.bind(this)}
                  value={user.email}
                  required
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="code">Exam Code (Optional)</InputLabel>
                <Input
                  id="code"
                  name="code"
                  autoComplete="code"
                  type="text"
                  onChange={this.handleInput.bind(this)}
                  value={user.code}
                />
              </FormControl>
              <br />
              <br />
              <Typography variant="headline" component="h6">
                {"Please choose either General Training or Academic IELTS: "}
              </Typography>
              <div className="text-center">
                <FormControlLabel
                  label="General Training"
                  control={
                    <Radio
                      name="exam_type"
                      className="answer-radio"
                      value="GT"
                      label="General Training"
                      onClick={this.handleInput.bind(this)}
                      checked={user.exam_type === "GT"}
                    />
                  }
                />
                <FormControlLabel
                  label="Academic"
                  control={
                    <Radio
                      name="exam_type"
                      className="answer-radio"
                      value="AC"
                      label="General Training"
                      onClick={this.handleInput.bind(this)}
                      checked={user.exam_type === "AC"}
                    />
                  }
                />
              </div>
              <Button
                type="submit"
                fullWidth
                variant="raised"
                color="primary"
                className={classes.submit}
              >
                Proceed
              </Button>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.registration.user,
    done: state.registration.user.done,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Registration));
