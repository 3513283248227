import { combineReducers } from 'redux';

import app from './app';
import dialog from './dialog';
import registration from './registration';
import readingExam from './readingExam';
import writingExam from './writingExam';
import listeningExam from './listeningExam';
import loadingPage from './loadingPage';
import speakingExam from './speakingExam';

export default combineReducers({
  app,
  dialog,
  listeningExam,
  readingExam,
  registration,
  writingExam,
  speakingExam,
  loadingPage,
});