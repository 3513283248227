// Common
export const RESET_EXAM_SETTINGS = 'RESET_EXAM_SETTINGS';
// Reading Exam
export const UPDATE_READING_EXAM_PAGE_NUMBER = 'UPDATE_READING_EXAM_PAGE_NUMBER';
export const SET_READING_EXAM_TIME = 'SET_READING_EXAM_TIME';
export const UPDATE_READING_EXAM_ANSWER = 'UPDATE_READING_EXAM_ANSWER';
// Writing Exam
export const UPDATE_WRITING_EXAM_PAGE_NUMBER = 'UPDATE_WRITING_EXAM_PAGE_NUMBER';
export const SET_WRITING_EXAM_TIME = 'SET_WRITING_EXAM_TIME';
export const UPDATE_WRITING_EXAM_ANSWER = 'UPDATE_WRITING_EXAM_ANSWER';
// Listening Exam
export const UPDATE_LISTENING_EXAM_PAGE_NUMBER = 'UPDATE_LISTENING_EXAM_PAGE_NUMBER';
export const SET_LISTENING_EXAM_TIME = 'SET_LISTENING_EXAM_TIME';
export const UPDATE_LISTENING_EXAM_ANSWER = 'UPDATE_LISTENING_EXAM_ANSWER';
export const UPDATE_LISTENING_EXAM_OR_ANSWER = 'UPDATE_LISTENING_EXAM_OR_ANSWER';
export const UPDATE_LISTENING_EXAM_AUDIO_PLAYING = 'UPDATE_LISTENING_EXAM_AUDIO_PLAYING';
export const UPDATE_LISTENING_EXAM_CURRENT_AUDIO_INDEX = 'UPDATE_LISTENING_EXAM_CURRENT_AUDIO_INDEX';
export const UPDATE_AUDIO_POSITION = 'UPDATE_AUDIO_POSITION';
export const UPDATE_COUNTDOWN_TRIGGER = 'UPDATE_COUNTDOWN_TRIGGER';
// Speaking exam
export const UPDATE_SPEAKING_EXAM_CURRENT_AUDIO_INDEX = 'UPDATE_SPEAKING_EXAM_CURRENT_AUDIO_INDEX';
export const UPDATE_SPEAKING_EXAM_AUDIO_POSITION = 'UPDATE_SPEAKING_EXAM_AUDIO_POSITION';
export const UPDATE_SPEAKING_EXAM_AUDIO_PLAYING_STATUS = 'UPDATE_SPEAKING_EXAM_AUDIO_PLAYING_STATUS';
export const UPDATE_SPEAKING_EXAM_RECORDING_STATUS = 'UPDATE_SPEAKING_EXAM_RECORDING_STATUS';
export const UPDATE_SPEAKING_EXAM_TIMER = 'UPDATE_SPEAKING_EXAM_TIMER';
export const UPDATE_SPEAKING_EXAM_TIMER2 = 'UPDATE_SPEAKING_EXAM_TIMER2';
export const UPDATE_SPEAKING_EXAM_RECORDING_INDEX = "UPDATE_SPEAKING_EXAM_RECORDING_INDEX";
export const UPDATE_SPEAKING_EXAM_PART2_STATUS = "UPDATE_SPEAKING_EXAM_PART2_STATUS";
export const UPDATE_SPEAKING_EXAM_START_STATUS = "UPDATE_SPEAKING_EXAM_START_STATUS";
export const UPDATE_SPEAKING_EXAM_SKIP_EXAM = "UPDATE_SPEAKING_EXAM_SKIP_EXAM";
export const UPDATE_SPEAKING_EXAM_SHOW_RESUME = "UPDATE_SPEAKING_EXAM_SHOW_RESUME";