import React from "react";
import Registration from "./components/main/Registration";
import Reading from "./components/main/Reading";
import Writing from "./components/main/Writing";
import Listening from "./components/main/Listening";
import Profile from "./components/main/Profile";
import Speaking from "./components/main/Speaking";
import Loading from "./components/main/Loading";
import IncompatibleBrowser from "./components/main/IncompatibleBrowser";
import Finish from "./components/main/Finish";
import { connect } from "react-redux";
import {
  isMobile,
  isChrome,
  isFirefox,
  browserVersion,
} from "react-device-detect";

class App extends React.Component {
  doNotCopy(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }
  render() {
    const { currentPage } = this.props;

    let browserAllowed = true;
    if (!isChrome && !isFirefox) {
      browserAllowed = false;
    }

    if (isChrome) {
      browserAllowed = parseFloat(browserVersion) > 50.0;
    }

    if (isFirefox) {
      browserAllowed = parseFloat(browserVersion) > 30.0;
    }

    let osAllowed = true;
    if (isMobile) {
      osAllowed = false;
    }

    return (
      <div
        onCopy={this.doNotCopy.bind(this)}
        onCut={this.doNotCopy.bind(this)}
        onPaste={this.doNotCopy.bind(this)}
      >
        {!browserAllowed ? (
          <IncompatibleBrowser />
        ) : currentPage === -1 ? (
          <Loading />
        ) : currentPage === 0 ? (
          <Registration />
        ) : currentPage === 1 ? (
          <Profile />
        ) : currentPage === 2 ? (
          <Listening />
        ) : currentPage === 3 ? (
          <Reading />
        ) : currentPage === 4 ? (
          <Writing />
        ) : currentPage === 5 ? (
          <Speaking />
        ) : currentPage === 6 ? (
          <Finish />
        ) : (
          <h1>Uh oh. You seem to be lost.</h1>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.app.currentPage,
  };
};

export default connect(mapStateToProps)(App);
