import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { updatePage } from '../../actions/app/app';
import {
  SG_EXAM_DESTINATION_URL,
  INT_EXAM_DESTINATION_URL,
} from '../../constants/urls'

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%",
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    textAlign: 'center',
  },
});

class Finish extends React.Component{
  componentDidMount(){
    try {
      window.scrollTo(0, 0);
    } catch (e){}
  }
  render(){
    const { classes, user, dispatch } = this.props;
    if (!user.done){
      dispatch(updatePage(0));
    }
    if (user.country.toLowerCase() !== 'singapore'){
      window.location.replace(INT_EXAM_DESTINATION_URL);
    } else {
      window.location.replace(SG_EXAM_DESTINATION_URL);
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user : state.registration.user,
  }
};

export default connect(mapStateToProps)(withStyles(styles)(Finish));