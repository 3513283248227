import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import * as examActions from '../../actions/exam/writingExam';
import { sendWritingAnswer } from '../../actions/api/requests';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { updateAlertDialog } from '../../actions/dialog/dialog';
import { updateSendingRequest, updatePage } from '../../actions/app/app';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import wordcount from 'wordcount';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import pink from '@material-ui/core/colors/pink';

const styles = theme => ({
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    paddingTop    : theme.spacing.unit * 2,
    paddingBottom : theme.spacing.unit * 2,
  },
  paper: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '10vh',
    overflowY : 'scroll',
  },
  paper2: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '80vh',
    height    : '80vh',
    overflowY : 'auto',
    textAlign : 'center',
    width     : 'inherit',
  },
  paper5: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '80vh',
    height    : '80vh',
    overflowY : 'auto',
    textAlign : 'left',
    width     : 'inherit',
  },
  paper4: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '80vh',
    height    : '80vh',
    overflowY : 'auto',
    textAlign : 'left',
    width     : 'inherit',
  },
  center: {
    textAlign : "center",
  },
  textRight:{
    textAlign : "right",
  },
  pageBtn:{
    margin :"1px",
    height :"1vh",
    textTransform: "none",
  },
  time:{
    verticalAlign:"middle",
  },
  paper3: {
    backgroundColor: pink['A400'],
    color          : 'white',
  },
  blink:{
    backgroundColor : 'red',
    animation       : "blinker 1s linear infinite",
    color           : 'white',
  },
  red:{
    backgroundColor : 'red',
    color           : 'white',
  },
});

class WritingGT extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      handleAgree : this.finishExam.bind(this),
      dialogTitle : "Confirm",
      dialogText  : "Are you sure you want to finish the Writing Exam and move to the Speaking Exam?",
      yesBtnTitle : "YES",
      noBtnTitle  : "NO",
    };
  }
  changePage(page){
    const element = ReactDOM.findDOMNode(this.ref);
    if (element !== null) {
      try {
        element.scrollTo(0,0);
      } catch (e){}
    }
    try {
      window.scrollTo(0, 0);
    } catch (e){}
    const { dispatch } = this.props;
    dispatch(examActions.updatePageNumber(page));
  }
  tick(){
    const { dispatch, examTime } = this.props;
    const newTime = examTime-1;
    if (newTime >= 0){
      dispatch(examActions.setExamTime(examTime-1));
    } else {
      clearInterval(this.timer);
      this.finishExam();
    }
  }
  componentDidMount(){
    try {
      window.scrollTo(0, 0);
    } catch (e){}
    this.timer = setInterval(this.tick.bind(this), 1000);
    const { dispatch } = this.props;
    dispatch(updateSendingRequest(false));
    const urlParams = new URLSearchParams(window.location.search);
    const timer = urlParams.get('change_timer');
    if (timer){
      dispatch(examActions.setExamTime(parseInt(timer, 10)));
    }
  }
  componentWillUnmount(){
    clearInterval(this.timer);
    const { dispatch } = this.props;
    dispatch(examActions.reset());
  }
  handleAnswerChange(index, e){
    const { dispatch } = this.props;
    dispatch(examActions.updateAnswer(e.target.value, index));
  }
  confirmSubmit(){
    const { dispatch } = this.props
    dispatch(updateAlertDialog(true));
  }
  finishExam(){
    const { dispatch, answers, name, email, user_id } = this.props;
    dispatch(updateSendingRequest(true));
    dispatch(updateAlertDialog(false));
    dispatch(sendWritingAnswer(JSON.stringify(answers), name, email, user_id));
  }
  render(){
    const {
      currentPage,
      maxPage,
      examTime,
      classes,
      answers,
      done,
      dispatch,
      sendingRequest,
      examSet,
    } = this.props;
    if (done){
      dispatch(updatePage(5));
    }
    const examMin = Math.floor(examTime / 60);
    const examSec = examTime - examMin * 60;
    const PageNavigation = () => {
      let result = [];
      for (let i = 1; i <= maxPage; i++){
        result.push(
          <Button
            key={"page"+i}
            variant={i!==currentPage?"contained":"outlined"}
            color="secondary"
            size="small"
            className={classes.pageBtn}
            onClick={this.changePage.bind(this, i)}
          >
            {"Part " + i}
          </Button>
        );
      }
      return result;
    }

    let clockClass = classes.paper3;
    if ((examMin === 10 && examSec === 0) ||
        (examMin === 5 && examSec === 0)  ||
        ((examMin === 9 && examSec < 60) && (examMin === 9 && examSec > 50)) ||
        ((examMin === 4 && examSec < 60) && (examMin === 4 && examSec > 50))){
      clockClass = classes.blink;
    } else if (examMin < 10 ) {
      clockClass = classes.red;
    }

    return (
      <div>
        {
          examSet === 1?
          <div className={classes.root}>
          <div>
            <Grid container spacing={16}>
                <Grid item md={12}>
                  <Paper>
                    <Typography
                      component="p"
                      color="secondary"
                      align="center"
                      className={clockClass}
                    >
                      <AccessAlarmIcon fontSize="inherit" className={classes.time}/> Time remaining: {examMin}m {examSec}s
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            {
              currentPage === 1?
                <Grid container spacing={16}>
                  <Grid item md={6} className={classes.center}>
                    <Paper className={classes.paper4}>
                      <Typography variant="headline" component="h6">
                        Writing Part 1
                      </Typography>
                      <Typography component="p" className="q-content">
                        {"You should spend about 20 minutes on this task.\n"}
                        {"\nWrite at least 150 words\n"}
                        {"\nYou do "} <b>NOT</b> {" need to write any addresses.\n"}
                        {"\nBegin your letter as follows: \n"}
                        <b>{"\nDear _______,"}</b>
                      </Typography>
                      <br/>
                      <div className="black-box">
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>You are going to another country to study. You would like to a part-time job while you are studying, so you want to ask a friend who lives there for some help.</b>
                        </Typography>
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>Write a letter to this friend. In your letter
                          <ul>
                            <li>give details of your study plans</li>
                            <li>explain why you want to get a part-time job</li>
                            <li>suggest how your friend could you find a job</li>
                          </ul>
                          </b>
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item md={6}>
                    <Grid item md={12}>
                      <Paper className={classes.paper2}>
                        <textarea
                          onChange={this.handleAnswerChange.bind(this, 0)}
                          value={answers[0]}
                          className="blank textfield-answer"
                          width="95%"
                        />
                        <br/>
                        <Typography component="p">
                          <b>Word Count:</b>&nbsp;{wordcount(answers[0])}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              :
                <Grid container spacing={16}>
                  <Grid item md={6} className={classes.center}>
                    <Paper className={classes.paper5}>
                      <Typography variant="headline" component="h6">
                        Writing Part 2
                      </Typography>
                      <Typography component="p" className="q-content">
                        {"You should spend about 40 minutes on this task.\n\nWrite about the topic below.\n\nGive reasons for your answer and include any relevant examples from you own knowledge or experience.\n\nWrite at least 250 words. "}
                      </Typography>
                      <br/>
                      <div className="black-box">
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>In many countries, the amount of crime is increasing. </b>
                        </Typography>
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>What do you think are the main causes of crime?</b>
                        </Typography>
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>How can we deal with those causes?</b>
                        </Typography>
                        <br/>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item md={6}>
                    <Grid item md={12}>
                      <Paper className={classes.paper2}>
                        <textarea
                          onChange={this.handleAnswerChange.bind(this, 1)}
                          value={answers[1]}
                          className="blank textfield-answer"
                        />
                        <br/>
                        <Typography component="p">
                          <b>Word Count:</b>&nbsp;{wordcount(answers[1])}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
  
            }
          </div>
          <Grid container spacing={16}>
            <Grid item md={6}>
              <PageNavigation/>
            </Grid>
            <Grid item md={6} className={classes.textRight}>
              <Button
                key="submitBtn"
                onClick={this.confirmSubmit.bind(this)}
                color="secondary"
                variant="contained"
                size="small"
                className={classes.pageBtn}
                disabled={sendingRequest}
              >
                {"Finish Writing Test Early And Proceed To Speaking Test"}&nbsp;<DoneOutlineIcon fontSize="inherit" />
              </Button>
            </Grid>
          </Grid>
          <ConfirmDialog {...this.state} />
        </div>
          :
          <div className={classes.root}>
          <div>
            <Grid container spacing={16}>
                <Grid item md={12}>
                  <Paper>
                    <Typography
                      component="p"
                      color="secondary"
                      align="center"
                      className={clockClass}
                    >
                      <AccessAlarmIcon fontSize="inherit" className={classes.time}/> Time remaining: {examMin}m {examSec}s
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            {
              currentPage === 1?
                <Grid container spacing={16}>
                  <Grid item md={6} className={classes.center}>
                    <Paper className={classes.paper4}>
                      <Typography variant="headline" component="h6">
                        Writing Part 1
                      </Typography>
                      <Typography component="p" className="q-content">
                        {"You should spend about 20 minutes on this task.\n"}
                        {"\nWrite at least 150 words\n"}
                        {"\nYou do "} <b>NOT</b> {" need to write any addresses.\n"}
                        {"\nBegin your letter as follows: \n"}
                        {"\nDear Sir or Madam,"}
                      </Typography>
                      <br/>
                      <div className="black-box">
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>You and some friends ate a meal at a restaurant to celebrate a special occasion, and you were very pleased with the food and service.</b>
                        </Typography>
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>Write a letter to the restaurant manager. In your letter
                          <ul>
                            <li>give details of your visit to the restaurant</li>
                            <li>explain the reason for the celebration</li>
                            <li>say what was good about the food and the service</li>
                          </ul>
                          </b>
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item md={6}>
                    <Grid item md={12}>
                      <Paper className={classes.paper2}>
                        <textarea
                          onChange={this.handleAnswerChange.bind(this, 0)}
                          value={answers[0]}
                          className="blank textfield-answer"
                          width="95%"
                        />
                        <br/>
                        <Typography component="p">
                          <b>Word Count:</b>&nbsp;{wordcount(answers[0])}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              :
                <Grid container spacing={16}>
                  <Grid item md={6} className={classes.center}>
                    <Paper className={classes.paper5}>
                      <Typography variant="headline" component="h6">
                        Writing Part 2
                      </Typography>
                      <Typography component="p" className="q-content">
                        {"You should spend about 40 minutes on this task.\n\nWrite about the topic below.\n\nGive reasons for your answer and include any relevant examples from you own knowledge or experience.\n\nWrite at least 250 words. "}
                      </Typography>
                      <br/>
                      <div className="black-box">
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>Some parents buy their children a large number of toys to play with.</b>
                        </Typography>
                        <br/>
                        <Typography variant="headline" component="h6">
                          <b>What are the advantages and disadvantages for the child of having a large number of toys? </b>
                        </Typography>
                        <br/>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item md={6}>
                    <Grid item md={12}>
                      <Paper className={classes.paper2}>
                        <textarea
                          onChange={this.handleAnswerChange.bind(this, 1)}
                          value={answers[1]}
                          className="blank textfield-answer"
                        />
                        <br/>
                        <Typography component="p">
                          <b>Word Count:</b>&nbsp;{wordcount(answers[1])}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
  
            }
          </div>
          <Grid container spacing={16}>
            <Grid item md={6}>
              <PageNavigation/>
            </Grid>
            <Grid item md={6} className={classes.textRight}>
              <Button
                key="submitBtn"
                onClick={this.confirmSubmit.bind(this)}
                color="secondary"
                variant="contained"
                size="small"
                className={classes.pageBtn}
                disabled={sendingRequest}
              >
                {"Finish Writing Test Early And Proceed To Speaking Test"}&nbsp;<DoneOutlineIcon fontSize="inherit" />
              </Button>
            </Grid>
          </Grid>
          <ConfirmDialog {...this.state} />
        </div>
        }
      </div>
    );
  }
}

WritingGT.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    name        : state.registration.user.name,
    email       : state.registration.user.email,
    user_id     : state.registration.user.id,
    user        : state.registration.user,
    currentPage : state.writingExam.pageNumber,
    maxPage     : state.writingExam.maxPage,
    examTime    : state.writingExam.examTime,
    answers     : state.writingExam.answers,
    finish      : state.loadingPage.currentIndex,
    done        : state.registration.user.done,
    examSet     : state.app.examSet,
    sendingRequest : state.app.sendingRequest,
  }
};

export default connect(mapStateToProps)(withStyles(styles)(WritingGT));