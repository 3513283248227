import * as actions from '../actions/types/loadingPage';

const defaultState = {
  loadTime     : 120,
  currentIndex : 0,
  messages     : [
    'Your IELTS Listening test will start in 2 minute. Please get ready.',
    'Your IELTS Reading test will start in 2 minutes. Please get ready.',
    'Your IELTS Writing test will start in 2 minutes. Please get ready.',
    'Your IELTS Speaking test will start in 2 minutes. Please get ready.',
    'Your Listening, Reading and Writing test is over.',
  ],
  btnMessages  : [
    "Click to start Listening Test immediately",
    "Click to start Reading Test immediately",
    "Click to start Writing Test immediately",
  ],
  nextPages    : [
    2,
    3,
    4,
    5,
    6,
  ],
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.SET_LOADING_PAGE_TIME: {
      return {...state, loadTime: action.payload};
    }
    case actions.UPDATE_LOADING_PAGE_CURRENT_INDEX: {
      return {...state, currentIndex: action.payload};
    }
    case actions.RESET_LOAD_PAGE_SETTINGS: {
      return {...defaultState};
    }
    default: return {...state};
  }
};