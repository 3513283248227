import {
  TOGGLE_ALERT_DIALOG,
  TOGGLE_WARNING_DIALOG,
  SET_ALERT_DIALOG,
} from '../actions/types/dialog'

const defaultState = {
  alertDialog   : false,
  warningDialog : false,
}

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case TOGGLE_ALERT_DIALOG: {
      return {...state, alertDialog: !state.alertDialog};
    }
    case SET_ALERT_DIALOG: {
      return {...state, alertDialog: action.payload};
    }
    case TOGGLE_WARNING_DIALOG: {
      return {...state, warningDialog: !state.warningDialog};
    }
    default: return {...state};
  }
}