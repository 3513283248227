import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { connect } from 'react-redux'
import { toggleWarningDialog } from '../../actions/dialog/dialog';

class WarningDialog extends React.Component {
  toggleModal = () => {
    const { dispatch } = this.props;
    dispatch(toggleWarningDialog(this.props.status));
  };
  render() {
    const {
      warningBtnTitle, 
      warningText,
      warningTitle,
    }  = this.props;
    return (
      <div>
        <Dialog
          open={this.props.status}
          onClose={this.toggleModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> { warningTitle } </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              { warningText } 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleModal.bind(this)} color="primary" autoFocus>
              { warningBtnTitle }
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    status: state.dialog.warningDialog,
  }
};
export default connect(mapStateToProps)(WarningDialog);