import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import * as examActions from '../../actions/exam/listeningExam';
import { sendReadingAnswer } from '../../actions/api/requests';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { updateAlertDialog } from '../../actions/dialog/dialog';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import pink from '@material-ui/core/colors/pink';
import Sound from '../others/Sounds';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { updateSendingRequest, updatePage } from '../../actions/app/app';

const styles = theme => ({
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    paddingTop    : theme.spacing.unit * 2,
    paddingBottom : theme.spacing.unit * 2,
  },
  paper: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '800px',
    overflowY : 'auto',
  },
  paper2: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '800px',
    height    : '800px',
    overflowY : 'auto',
    textAlign : 'center',
  },
  center: {
    textAlign : "center",
  },
  pageBtn:{
    margin :"1px",
    height :"1vh",
    textTransform: "none",
  },
  time:{
    verticalAlign:"middle",
  },
  paper3: {
    backgroundColor: pink['A400'],
    color          : 'white',
  },
  redPaper: {
    backgroundColor: 'red',
    color          : 'white',
  },
  paper4: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '80vh',
    height    : '80vh',
    overflowY : 'auto',
  },
  textRight:{
    textAlign : "right",
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  autoMargin: {
    margin: 'auto',
    textAlign: 'center',
  },
  table: {
    minWidth: "700px",
    width: "70%",
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  borderTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1) !important",
  },
  borderPaddingTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1) !important",
    paddingTop:"16px",
  },
});

class Listening extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      dialogTitle    : "Confirm",
      dialogText     : "Are you sure you want to finish the Listening Exam and move on to the Reading Exam?",
      yesBtnTitle    : "YES",
      noBtnTitle     : "NO",
      handleAgree    : this.finishExam.bind(this),
      startCountdown : this.startCountdown.bind(this),
    };
    this.paper = React.createRef();
  }
  changePage(page){
    const { dispatch } = this.props;
    dispatch(examActions.updatePageNumber(page));
    const element = ReactDOM.findDOMNode(this.ref);
    if (element != null){
      element.scrollTo(0,0);
      const iframe = document.getElementsByTagName("IFRAME")[0];
      if (iframe){
        iframe.scrollTo(0,0);
      }
    }
  }
  tick(){
    const { dispatch, examTime } = this.props;
    const newTime = examTime-1;
    if (newTime >= 0){
      dispatch(examActions.setExamTime(examTime-1));
    } else {
      clearInterval(this.timer);
      this.finishExam();
    }
  }
  componentWillUnmount(){
    clearInterval(this.timer);
    const { dispatch } = this.props;
    dispatch(examActions.reset());
  }
  startCountdown(){
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  componentDidMount(){
    const { dispatch, triggered } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const audioIndex = urlParams.get('audio');
    if (audioIndex){
      dispatch(examActions.updateCurrentAudioIndex(parseInt(audioIndex, 10) - 1));
    }
    const position = urlParams.get('position');
    if (position){
      dispatch(examActions.updateAudioPosition(parseInt(position, 10) * 1000));
    }
    dispatch(updateSendingRequest(false));
    if (triggered){
      this.timer = setInterval(this.tick.bind(this), 1000);
    }
  }
  componentDidUpdate() {
  }
  handleAnswerChange(index, e){
    const { dispatch } = this.props;
    dispatch(examActions.updateAnswer(e.target.value, index));
  }
  handleOrAnswerChange(e){
    const { dispatch, orAnswers, examSet } = this.props;
    const value = e.target.value;
    if (examSet === 1){
      let newOrAnswers = orAnswers[0].slice(0);
      if (e.target.checked){
        if (newOrAnswers.length < 2){
          newOrAnswers.push(value);
        }
      } else {
        const i = newOrAnswers.indexOf(value);
        newOrAnswers.splice(i, 1);
      }
      let finalAnswer = orAnswers.slice(0);
      finalAnswer[0] = newOrAnswers;
      dispatch(examActions.updateOrAnswer(finalAnswer));
    } else if (examSet === 2){
      const num = e.target.name;
      let newOrAnswers;
      let finalAnswer = orAnswers.slice(0);
      if (num === '21'){
        newOrAnswers = orAnswers[0].slice(0);
        if (e.target.checked){
          if (newOrAnswers.length < 2){
            newOrAnswers.push(value);
          }
        } else {
          const i = newOrAnswers.indexOf(value);
          newOrAnswers.splice(i, 1);
        }
        orAnswers[0] = newOrAnswers;
        finalAnswer[0] = newOrAnswers;
      } else {
        newOrAnswers = orAnswers[1].slice(0);
        if (e.target.checked){
          if (newOrAnswers.length < 2){
            newOrAnswers.push(value);
          }
        } else {
          const i = newOrAnswers.indexOf(value);
          newOrAnswers.splice(i, 1);
        }
        orAnswers[1] = newOrAnswers;
        finalAnswer[1] = newOrAnswers;
      }
      dispatch(examActions.updateOrAnswer(finalAnswer));
    }
  }
  confirmSubmit(){
    const { dispatch } = this.props;
    dispatch(updateAlertDialog(true));
  }
  finishExam(){
    const { dispatch, answers, orAnswers, name, email, user_id, history, examSet } = this.props;
    dispatch(updateSendingRequest(true));
    if (examSet === 1){
      answers[10] = orAnswers[0][0];
      answers[11] = orAnswers[0][1];
    } else {
      answers[20] = orAnswers[0][0];
      answers[21] = orAnswers[0][1];
      answers[22] = orAnswers[1][0];
      answers[23] = orAnswers[1][1];
    }
    dispatch(updateAlertDialog(false));
    let examId = 94;
    if (examSet === 2){
      examId = 1136;
    }
    dispatch(sendReadingAnswer(answers.join('{x}'), name, email, user_id, examId, history));
  }
  render(){
    const {
      currentPage,
      maxPage,
      classes,
      answers,
      orAnswers,
      dispatch,
      done,
      currentIndex,
      sendingRequest,
      examTime,
      triggered,
      examSet,
    } = this.props;

    if (!triggered && !(currentIndex < maxPage)){
      dispatch(examActions.updateCountdownTrigger(true));
      this.startCountdown();
    }

    if (done){
      dispatch(updatePage(5));
    }

    const examMin = Math.floor(examTime / 60);
    const examSec = examTime - examMin * 60;
    const identification = 12;

    const PageNavigation = () => {
      const result = [];
      for (let i = 1; i <= maxPage; i++){
        result.push(
          <Button
            key={"page"+i}
            variant={i!==currentPage?"contained":"outlined"}
            color="secondary"
            size="small"
            className={classes.pageBtn}
            onClick={this.changePage.bind(this, i)}
          >
            {"Section " + i}
          </Button>
        );
      }
      return result;
    }
    return (
      <div className={classes.root}>
        {
          examSet===1?
          <div>
          <div>
          <Grid container spacing={16}>
            <Grid item md={12}>
              <Paper>
                <Typography
                  component="p"
                  align="center"
                  className={currentIndex < maxPage? classes.paper3 : classes.redPaper}
                >
                  {
                    currentIndex < maxPage?
                      "You are now in Listening Section " + (currentIndex+1)
                        :
                      "You now have " + examMin + "m " + examSec + "s to look through ALL your Listening Answers before you proceed to the Reading test. "
                  }
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          {
            currentPage === 1?
              <Grid container spacing={16}>
                <Grid item lg={12}>
                  <Paper className={classes.paper4} ref={(node) => this.ref = node}>
                    <Grid container spacing={16} >
                      <Grid item lg={12}>
                      <br/>
                        <table width="100%" className={classes.table} cellPadding="10" cellSpacing="2" align="center">
                          <thead>
                            <tr>
                              <td colSpan="3">
                                <Typography>
                                  <b>SECTION 1 <i>Questions 1-10</i></b>
                                </Typography>
                                <Typography>
                                  <i>Questions 1-6</i>
                                </Typography>
                                <Typography>
                                  <i>Complete the notes below.</i>
                                </Typography>
                                <Typography>
                                  <i>Write <b>ONE WORD</b> for each answer.</i>
                                </Typography>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan="3" className={classes.borderPaddingTop}>
                                <Typography variant="headline" component="h6" className="text-center">
                                  <b>SELF-DRIVE TOURS IN THE USA</b>
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3" className={classes.borderPaddingTop}>
                                <Typography>
                                  <i>Example</i>
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td width="33%">
                                <Typography>
                                  <b>Name:</b>
                                </Typography>
                              </td>
                              <td colSpan="2">
                                <Typography>
                                  <b>Andrea _______________ Brown</b>
                                </Typography>
                              </td>
                            </tr>
                            <tr><td colSpan="3" className={classes.borderTop}>&nbsp;</td></tr>
                            <tr>
                              <td width="33%">
                                <Typography>
                                  <b>Address:</b>
                                </Typography>
                              </td>
                              <td colSpan="2">
                                <Typography>
                                  24 &nbsp;
                                  <b>
                                  1
                                  </b>
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 0)}
                                    value={answers[0]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                &nbsp;
                                Road
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td><Typography><b>Postcode:</b></Typography></td>
                              <td colSpan="2"><Typography>BH5 2OP</Typography></td>
                            </tr>
                            <tr>
                              <td><Typography><b>Phone:</b></Typography></td>
                              <td colSpan="2"><Typography>(mobile) 077 8664 3091</Typography></td>
                            </tr>
                            <tr>
                              <td><Typography><b>Heard about company from:</b></Typography></td>
                              <td colSpan="2">
                                <span className="questions-listening">
                                  <b>2</b>
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 1)}
                                    value={answers[1]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3"><Typography><b>Possible self-drive tours</b></Typography></td>
                            </tr>
                            <tr><td colSpan="3">&nbsp;</td></tr>
                            <tr><td colSpan="3"><Typography>Trip One:</Typography></td></tr>
                            <tr>
                              <td colSpan="3">
                                  <ul>
                                    <li>
                                      <Typography>
                                      Los Angeles: customer wants to visit some <b>3</b>&nbsp;
                                      <Input
                                        onChange={this.handleAnswerChange.bind(this, 2)}
                                        value={answers[2]}
                                        className="blank"
                                        inputProps={{size:identification}}
                                      /> parks with her children
                                      </Typography>
                                    </li>
                                    <li>
                                      <Typography>
                                      Yosemite Park: customer wants to stay in a lodge, not a <b>4</b>&nbsp;
                                      <Input
                                        onChange={this.handleAnswerChange.bind(this, 3)}
                                        value={answers[3]}
                                        className="blank"
                                        inputProps={{size:identification}}
                                      />
                                      </Typography>
                                    </li>
                                  </ul>
                              </td>
                            </tr>
                            <tr><td colSpan="3"><Typography>Trip Two:</Typography></td></tr>
                            <tr>
                              <td colSpan="3">
                                  <ul>
                                    <li>
                                      <Typography>
                                      Customer wants to see the &nbsp;
                                      <span className="questions-listening">
                                        <b>5</b>
                                        <Input
                                          onChange={this.handleAnswerChange.bind(this, 4)}
                                          value={answers[4]}
                                          className="blank"
                                          inputProps={{size:identification}}
                                        />
                                      </span> on the way to Cambria
                                      </Typography>
                                    </li>
                                    <li>
                                      <Typography>At Santa Monica: not interested in shopping</Typography>
                                    </li>
                                    <li>
                                      <Typography>
                                        At San Diego, wants to spend time on the <b>6</b>&nbsp;
                                          <Input
                                            onChange={this.handleAnswerChange.bind(this, 5)}
                                            value={answers[5]}
                                            className="blank"
                                            inputProps={{size:identification}}
                                          />
                                      </Typography>
                                    </li>
                                  </ul>
                              </td>
                            </tr>
                            <tr><td colSpan="3">&nbsp;</td></tr>
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <table width="100%" className={classes.table} cellPadding="10" cellSpacing="0" align="center">
                          <thead>
                            <tr>
                              <td colSpan="3">
                                <Typography>
                                  <i>Questions 7-10</i>
                                </Typography>
                                <Typography>
                                  <i>Complete the table below.</i>
                                </Typography>
                                <Typography>
                                  <i>Write <b>ONE WORD AND/OR A NUMBER</b> for each answer.</i>
                                </Typography>
                              </td>
                            </tr>
                          </thead>
                          <tbody className="table2">
                            <tr>
                              <td>
                              </td>
                              <td>
                                <Typography><b>Number of days</b></Typography>
                              </td>
                              <td>
                                <Typography><b>Total distance</b></Typography>
                              </td>
                              <td>
                                <Typography><b>Price (per person)</b></Typography>
                              </td>
                              <td>
                                <Typography><b>Includes</b></Typography>
                              </td>
                            </tr>
                            <tr>
                              <td><Typography><b>Trip One</b></Typography></td>
                              <td><Typography>12 days</Typography></td>
                              <td>
                                <Typography>
                                <span className="questions">
                                  <b>7</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 6)}
                                    value={answers[6]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> &nbsp;km
                                </span>
                                </Typography>
                              </td>
                              <td><Typography>£525</Typography></td>
                              <td>
                                <Typography>
                                  <ul>
                                    <li>
                                      accomodation
                                    </li>
                                    <li>
                                      car
                                    </li>
                                    <li>
                                      one <span className="questions">
                                      <b>8</b>&nbsp;
                                        <Input
                                          onChange={this.handleAnswerChange.bind(this, 7)}
                                          value={answers[7]}
                                          className="blank"
                                          inputProps={{size:identification}}
                                        />
                                      </span>
                                    </li>
                                  </ul>
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td><Typography><b>Trip Two</b></Typography></td>
                              <td><Typography>9 days</Typography></td>
                              <td><Typography>980 km</Typography></td>
                              <td>
                                <Typography>
                                  <span className="questions">
                                    <b>9</b> £ &nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 8)}
                                      value={answers[8]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                  </span>
                                </Typography>
                              </td>
                              <td>
                                <Typography>
                                  <ul>
                                    <li>
                                      accomodation
                                    </li>
                                    <li>
                                      car
                                    </li>
                                    <li>
                                      one <span className="questions">
                                      <b>10</b>&nbsp;
                                        <Input
                                          onChange={this.handleAnswerChange.bind(this, 9)}
                                          value={answers[9]}
                                          className="blank"
                                          inputProps={{size:identification}}
                                        />
                                      </span>
                                    </li>
                                  </ul>
                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            :
            currentPage === 2?
              <Grid container spacing={16}>
                <Grid item lg={12}>
                  <Paper className={classes.paper4} ref={(node) => this.ref = node}>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <div className="center-div">
                        <Typography className="q-content">
                          <b>{"SECTION 2 "}<i>{" Questions 11-20 "}</i></b>
                          <i>{"\nQuestions 11–12\nChoose "}<b>TWO</b>{" letters "}<b>A-E</b>{"."}</i>
                        </Typography>
                          <br/>
                          <Typography className="q-content">
                            Which <b>TWO</b> facilities at the leisure club have recently been improved?
                          </Typography>
                          <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                              <FormControlLabel
                                className="chkbox-answer"
                                control={
                                  <Checkbox
                                    value="A"
                                    checked={orAnswers[0].indexOf('A') > -1}
                                    onClick={this.handleOrAnswerChange.bind(this)}
                                  />
                                }
                                label="A. the gym"
                              />
                              <FormControlLabel
                                className="chkbox-answer"
                                control={
                                  <Checkbox
                                    value="B"
                                    checked={orAnswers[0].indexOf('B') > -1}
                                    onClick={this.handleOrAnswerChange.bind(this)}
                                  />
                                }
                                label="B. the tracks"
                              />
                              <FormControlLabel
                                className="chkbox-answer"
                                control={
                                  <Checkbox
                                    value="C"
                                    checked={orAnswers[0].indexOf('C') > -1}
                                    onClick={this.handleOrAnswerChange.bind(this)}
                                  />
                                }
                                label="C. the indoor pool"
                              />
                              <FormControlLabel
                                className="chkbox-answer"
                                control={
                                  <Checkbox
                                    value="D"
                                    checked={orAnswers[0].indexOf('D') > -1}
                                    onClick={this.handleOrAnswerChange.bind(this)}
                                  />
                                }
                                label="D. the outdoor pool"
                              />
                              <FormControlLabel
                                className="chkbox-answer"
                                control={
                                  <Checkbox
                                    value="E"
                                    checked={orAnswers[0].indexOf('E') > -1}
                                    onClick={this.handleOrAnswerChange.bind(this)}
                                  />
                                }
                                label="E. the sports training for children"
                              />
                            </FormGroup>
                          </FormControl>
                          <br/><br/>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <div className="center-div">
                          <Typography>
                            <i>Questions 13-20</i>
                          </Typography>
                          <Typography>
                            <i>Complete the notes below.</i>
                          </Typography>
                          <Typography>
                            <i>Write <b>NO MORE THAN TWO WORDS</b> for each answer.</i>
                          </Typography>
                          <br/>
                          <Typography variant="headline" component="h6" className="text-center">
                            <b>{"Joining the leisure club"}</b>
                          </Typography>
                          <Typography>
                            <i>{"Personal Assessment"}</i>
                            <ul>
                                <li>New members should describe any <b>13</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 12)}
                                    value={answers[12]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                                <li>The <b>14</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 13)}
                                    value={answers[13]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> will be explained to you before you use the equipment.
                                </li>
                                <li>You will be given a six-week <b>15</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 14)}
                                    value={answers[14]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                            </ul>
                          </Typography>
                          <Typography>
                          <i>{"Types of membership"}</i>
                          <ul>
                              <li>There is a compulsory £90 <b>16</b>&nbsp;
                                <Input
                                  onChange={this.handleAnswerChange.bind(this, 15)}
                                  value={answers[15]}
                                  className="blank"
                                  inputProps={{size:identification}}
                                /> fee for members.
                              </li>
                              <li>Gold members are given <b>17</b>&nbsp;
                                <Input
                                  onChange={this.handleAnswerChange.bind(this, 16)}
                                  value={answers[16]}
                                  className="blank"
                                  inputProps={{size:identification}}
                                /> to all the LP clubs.
                              </li>
                              <li>Premier members are given priority during <b>18</b>&nbsp;
                                <Input
                                  onChange={this.handleAnswerChange.bind(this, 17)}
                                  value={answers[17]}
                                  className="blank"
                                  inputProps={{size:identification}}
                                /> hours.
                              </li>
                              <li>Premier members can bring some <b>19</b>&nbsp;
                                <Input
                                  onChange={this.handleAnswerChange.bind(this, 18)}
                                  value={answers[18]}
                                  className="blank"
                                  inputProps={{size:identification}}
                                /> every month.
                              </li>
                              <li>Members should always take their <b>20</b>&nbsp;
                                <Input
                                  onChange={this.handleAnswerChange.bind(this, 19)}
                                  value={answers[19]}
                                  className="blank"
                                  inputProps={{size:identification}}
                                /> with them.
                              </li>
                          </ul>
                        </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            :
            currentPage === 3?
              <Grid container spacing={16}>
                <Grid item lg={12}>
                  <Paper className={classes.paper4} ref={(node) => this.ref = node}>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <div className="center-div">
                              <Typography className="q-content">
                                <b>{"SECTION 3 "}<i>{" Questions 21-30 "}</i></b>
                                <i>{"\nQuestions 21–25\nChoose the correct letter "}<b> {"A, B,"}</b>{" or "}<b>C.</b></i>
                              </Typography>
                              <br/>
                              <Typography className="q-content">
                                <b>Global Design Competition</b>
                              </Typography>
                              <br/>
                              <div>
                                <span className="questions">
                                  21. Students entering the design competition have to
                                </span>
                                <br/>
                                <span className="questions">
                                  <RadioGroup
                                    aria-label="question 21"
                                    name="q21"
                                    value={answers[20]}
                                    className={classes.group}
                                  >
                                    <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 20)} control={<Radio />} label="A.	produce an energy-efficient design." />
                                    <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 20)} control={<Radio />} label="B.	adapt an existing energy-saving appliance." />
                                    <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 20)} control={<Radio />} label="C.	develop a new use for current technology. " />
                                  </RadioGroup>
                                </span>
                              </div>
                              <br/>
                              <div>
                                <span className="questions">
                                  22.	John chose a dishwasher because he wanted to make dishwashers
                                </span>
                                <br/>
                                <span className="questions">
                                  <RadioGroup
                                    aria-label="question 22"
                                    name="q22"
                                    value={answers[21]}
                                    className={classes.group}
                                  >
                                    <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 21)} control={<Radio />} label="A.	more appealing." />
                                    <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 21)} control={<Radio />} label="B.	more common." />
                                    <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 21)} control={<Radio />} label="C.	more economical." />
                                  </RadioGroup>
                                </span>
                              </div>
                              <br/>
                              <div>
                                <span className="questions">
                                  23.	The stone in John's 'Rockpool design is used
                                </span>
                                <br/>
                                <span className="questions">
                                  <RadioGroup
                                    aria-label="question 23"
                                    name="q23"
                                    value={answers[22]}
                                    className={classes.group}
                                  >
                                    <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 22)} control={<Radio />} label="A.	for decoration." />
                                    <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 22)} control={<Radio />} label="B.	to switch it on." />
                                    <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 22)} control={<Radio />} label="C.	to stop water escaping." />
                                  </RadioGroup>
                                </span>
                              </div>
                              <br/>
                              <div>
                                <span className="questions">
                                  24.	In the holding chamber, the carbon dioxide
                                </span>
                                <br/>
                                <span className="questions">
                                  <RadioGroup
                                    aria-label="question 24"
                                    name="q24"
                                    value={answers[23]}
                                    className={classes.group}
                                  >
                                    <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 23)} control={<Radio />} label="A.	changes back to a gas." />
                                    <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 23)} control={<Radio />} label="B.	dries the dishes." />
                                    <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 23)} control={<Radio />} label="C.	is allowed to cool." />
                                  </RadioGroup>
                                </span>
                              </div>
                              <br/>
                              <div>
                                <span className="questions">
                                  25.	At the end of the cleaning process, the carbon dioxide
                                </span>
                                <br/>
                                <span className="questions">
                                  <RadioGroup
                                    aria-label="question 25"
                                    name="q25"
                                    value={answers[24]}
                                    className={classes.group}
                                  >
                                    <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 24)} control={<Radio />} label="A.	is released into the air." />
                                    <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 24)} control={<Radio />} label="B.	is disposed of with the waste." />
                                    <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 24)} control={<Radio />} label="C.	is collected ready to be re-used." />
                                  </RadioGroup>
                                </span>
                              </div>
                              <br/>
                        </div>
                      </Grid>
                      <Grid item lg={12}>
                        <div className="center-div">
                          <Typography>
                            <i>{"Questions 26-30"}</i>
                          </Typography>
                          <Typography>
                            <i>{"Complete the notes below."}</i>
                          </Typography>
                          <Typography>
                            <i>Write <b>ONE WORD ONLY</b> for each answer.</i>
                          </Typography>
                          <br/>
                          <div className="center-box">
                              <ul>
                                <li>
                                  <Typography>
                                    John needs help preparing for his <b>26</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 25)}
                                      value={answers[25]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    The professor advises John to make a <b>27</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 26)}
                                      value={answers[26]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />of his design.
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    John's main problem is getting good quality <b>28</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 27)}
                                      value={answers[27]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    The professor suggests John apply for a <b>29</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 28)}
                                      value={answers[28]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                  </Typography>
                                </li>
                                <li>
                                  <Typography>
                                    The professor will check the <b>30</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 29)}
                                      value={answers[29]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    /> information in John's written report.
                                  </Typography>
                                </li>
                              </ul>
                          </div>
                        </div>
                        <br/><br/>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            :
              <Grid container spacing={16}>
                <Grid item lg={12}>
                  <Paper className={classes.paper4} ref={(node) => this.ref = node}>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <div className="center-div">
                          <Typography>
                            <b>SECTION 4 <i>Questions 31-40</i></b>
                          </Typography>
                          <Typography>
                            <i>{"Complete the notes below."}</i>
                          </Typography>
                          <Typography>
                            <i>Write <b>ONE WORD ONLY</b> for each answer.</i>
                          </Typography>
                          <br/>
                          <div className="center-box">
                            <Typography variant="headline" component="h6" className="text-center">
                              <b>{"THE SPIRIT BEAR"}</b>
                            </Typography>
                            <Typography>
                              <b>General facts</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>It is a white bear belonging to the black bear family.</li>
                                <li>Its colour comes from an uncommon <b>31</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 30)}
                                    value={answers[30]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                                <li>Local people believe that it has unusual <b>32</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 31)}
                                    value={answers[31]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                                <li>They protect the bear from <b>33</b>&nbsp;
                                  <Input
                                      onChange={this.handleAnswerChange.bind(this, 32)}
                                      value={answers[32]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                </li>
                              </ul>
                            </Typography>
                            <Typography>
                              <b>Habitat</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>The bear's relationship with the forest is complex.</li>
                                <li>Tree roots stop <b>34</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 33)}
                                    value={answers[33]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> along salmon streams.
                                </li>
                                <li>The bears' feeding habits provide nutrients for forest vegetation.</li>
                                <li>It is currently found on a small number of <b>35</b>&nbsp;
                                    <Input
                                    onChange={this.handleAnswerChange.bind(this, 34)}
                                    value={answers[34]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                              </ul>
                            </Typography>
                            <Typography>
                              <b>Threats</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>Habitat is being lost due to deforestation and construction of <b>36</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 35)}
                                    value={answers[35]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> by logging companies.
                                </li>
                                <li>Unrestricted <b>37</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 36)}
                                    value={answers[36]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> is affecting the salmon supply.
                                </li>
                                <li>
                                  The bears' existence is also threatened by their low rate of <b>38</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 37)}
                                    value={answers[37]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                              </ul>
                            </Typography>
                            <Typography>
                              <b>Going forward</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>Interested parties are working together.</li>
                                <li>Logging companies must improve their <b>39</b> &nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 38)}
                                    value={answers[38]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                                <li>Maintenance and <b>40</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 39)}
                                    value={answers[39]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> of the spirit bears' territory is needed.
                                </li>
                              </ul>
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
          }
        </div>
        <Grid container spacing={16}>
          <Grid item lg={6}>
            <PageNavigation/>
          </Grid>
          <Grid item lg={6} className={classes.textRight}>
            <Button
              key="submitBtn"
              onClick={this.confirmSubmit.bind(this)}
              color="secondary"
              variant="contained"
              size="small"
              className={classes.pageBtn}
              disabled={sendingRequest}
            >
              {"Finish Listening Test Early And Proceed To Reading Test"}&nbsp;<DoneOutlineIcon fontSize="inherit" />
            </Button>
          </Grid>
        </Grid>
        <ConfirmDialog {...this.state} />
        { currentIndex > 3 ? null : <Sound {...this.state}/> }
         </div>
          :
          <div>
            <div>
            <Grid container spacing={16}>
              <Grid item md={12}>
                <Paper>
                  <Typography
                    component="p"
                    align="center"
                    className={currentIndex < maxPage? classes.paper3 : classes.redPaper}
                  >
                    {
                      currentIndex < maxPage?
                        "You are now in Listening Section " + (currentIndex+1)
                          :
                        "You now have " + examMin + "m " + examSec + "s to look through ALL your Listening Answers before you proceed to the Reading test. "
                    }
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            {
              currentPage === 1?
                <Grid container spacing={16}>
                  <Grid item lg={12}>
                    <Paper className={classes.paper4} ref={(node) => this.ref = node}>
                      <Grid container spacing={16} >
                        <Grid item lg={12}>
                        <br/>
                          <table width="100%" className={classes.table} cellPadding="10" cellSpacing="2" align="center">
                            <thead>
                              <tr>
                                <td colSpan="3">
                                  <Typography>
                                    <b>SECTION 1 <i>Questions 1-10</i></b>
                                  </Typography>
                                  <Typography>
                                    <i>Complete the notes below.</i>
                                  </Typography>
                                  <Typography>
                                    <i>Write <b>ONE WORD/OR A NUMBER</b> for each answer.</i>
                                  </Typography>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="3" className={classes.borderPaddingTop}>
                                  <Typography variant="headline" component="h5" className="text-center">
                                    <b>Transport Survey</b>
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.borderPaddingTop}>
                                  <Typography>
                                    <i>Example</i>
                                  </Typography>
                                  <br/>
                                  <Typography>
                                    <b>Travelled to town today:</b> by <u><i>bus</i></u>
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.borderPaddingTop}>
                                  <Typography>
                                    <b>Name:</b> Luisa <b>1</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 0)}
                                      value={answers[0]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Address: 2</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 1)}
                                      value={answers[1]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    /> White Stone Rd
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Area: </b> Bradfield
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Postcode: 3</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 2)}
                                      value={answers[2]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Occupation: 4</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 3)}
                                      value={answers[3]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Reason for visit to town: </b> to go to the <b>5</b>&nbsp;
                                    <Input
                                      onChange={this.handleAnswerChange.bind(this, 4)}
                                      value={answers[4]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    />
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Suggestions for improvement:</b>
                                    <ul>
                                      <li>
                                        better <b>6</b> &nbsp;
                                        <Input
                                          onChange={this.handleAnswerChange.bind(this, 5)}
                                          value={answers[5]}
                                          className="blank"
                                          inputProps={{size:identification}}
                                        />
                                      </li>
                                      <li>have more footpaths</li>
                                      <li>more frequent <b>7</b>&nbsp;
                                        <Input
                                          onChange={this.handleAnswerChange.bind(this, 6)}
                                          value={answers[6]}
                                          className="blank"
                                          inputProps={{size:identification}}
                                        />
                                      </li>
                                    </ul>
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography>
                                  <b>Things that would encourage cycling to work:</b>
                                  <ul>
                                    <li>
                                      having <b>8</b> &nbsp;
                                      <Input
                                        onChange={this.handleAnswerChange.bind(this, 7)}
                                        value={answers[7]}
                                        className="blank"
                                        inputProps={{size:identification}}
                                      /> parking places for bicycles
                                    </li>
                                    <li>being able to use a <b>9</b> &nbsp;
                                      <Input
                                        onChange={this.handleAnswerChange.bind(this, 8)}
                                        value={answers[8]}
                                        className="blank"
                                        inputProps={{size:identification}}
                                      /> at work
                                    </li>
                                    <li>the opportunity to have cycling <b>10</b>&nbsp;
                                      <Input
                                        onChange={this.handleAnswerChange.bind(this, 9)}
                                        value={answers[9]}
                                        className="blank"
                                        inputProps={{size:identification}}
                                      /> on busy roads
                                    </li>
                                  </ul>
                                  </Typography>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              :
              currentPage === 2?
              <Grid container spacing={16}>
                <Grid item lg={12}>
                  <Paper className={classes.paper4} ref={(node) => this.ref = node}>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <div className="center-div">
                        <Typography className="q-content">
                          <b>{"SECTION 2 "}<i>{" Questions 11-20 "}</i></b>
                          <i> {"\nQuestions 11–14"}
                              {"\nChoose the correct letter, "}
                              <b>A, B</b> or <b>C</b>{"."}</i>
                        </Typography>
                          <br/>
                          <Typography variant="headline" component="h6" className="text-center">
                            <b>New city developments</b>
                          </Typography>
                          <br/>
                            <div>
                              <span className="questions">
                                11.	The idea for the two new developments in the city came from
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 11"
                                  name="q11"
                                  value={answers[10]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 10)} control={<Radio />} label="A.	local people" />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 10)} control={<Radio />} label="B.	the City Council." />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 10)} control={<Radio />} label="C.	the SWRDC." />
                                </RadioGroup>
                              </span>
                              <br/>
                              <span className="questions">
                                12.	What is unusual about Brackenside pool?
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 12"
                                  name="q12"
                                  value={answers[11]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 11)} control={<Radio />} label="A.	its architectural style" />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 11)} control={<Radio />} label="B.	its heating system" />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 11)} control={<Radio />} label="C.	its method of water treatment" />
                                </RadioGroup>
                              </span>
                              <br/>
                              <span className="questions">
                                13.	Local newspapers have raised worries about
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 13"
                                  name="q13"
                                  value={answers[12]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 12)} control={<Radio />} label="A.	the late opening date." />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 12)} control={<Radio />} label="B.	the post of the project." />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 12)} control={<Radio />} label="C.	the size of the facilities." />
                                </RadioGroup>
                              </span>
                              <br/>
                              <span className="questions">
                                14.	What decision has not yet been made about the pool?
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 14"
                                  name="q14"
                                  value={answers[13]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 13)} control={<Radio />} label="A.	whose statue will be at the door" />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 13)} control={<Radio />} label="B.	the exact opening times" />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 13)} control={<Radio />} label="C.	who will open it" />
                                </RadioGroup>
                              </span>
                            </div>
                          <br/><br/>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <div className="center-div">
                          <Typography>
                            <i>Questions 15-20</i>
                          </Typography>
                          <Typography>
                            <i>Which feature is related to each of the following areas of the world represented in the playground?</i>
                          </Typography>
                          <Typography>
                            Choose <b>SIX</b> answers from the box and write the correct letter, <b>A – I</b>, next to questions 15-20
                          </Typography>
                          <br/>
                          <div className="center-box-vxsmall">
                            <Typography className="text-center">
                              <b>Features</b>
                            </Typography>
                            <Typography>
                              <b>A.</b>&nbsp;&nbsp;&nbsp; ancient forts <br/>
                              <b>B.</b>&nbsp;&nbsp;&nbsp; waterways <br/>
                              <b>C.</b>&nbsp;&nbsp;&nbsp; ice and snow <br/>
                              <b>D.</b>&nbsp;&nbsp;&nbsp; jewels <br/>
                              <b>E.</b>&nbsp;&nbsp;&nbsp; local animals <br/>
                              <b>F.</b>&nbsp;&nbsp;&nbsp; mountains <br/>
                              <b>G.</b>&nbsp;&nbsp;&nbsp; music and film <br/>
                              <b>H.</b>&nbsp;&nbsp;&nbsp; space travel <br/>
                              <b>I.</b>&nbsp;&nbsp;&nbsp; volcanoes <br/>
                            </Typography>
                          </div>
                          <br/>
                          <Typography>
                            <b>Areas of the world</b>
                            <br/><br/>
                            <b>15.</b> Asia <Input
                              onChange={this.handleAnswerChange.bind(this, 14)}
                              value={answers[14]}
                              className="blank"
                              inputProps={{size:identification}}
                            />
                            <br/><br/>
                            <b>16.</b> Antarctica <Input
                              onChange={this.handleAnswerChange.bind(this, 15)}
                              value={answers[15]}
                              className="blank"
                              inputProps={{size:identification}}
                            />
                            <br/><br/>
                            <b>17.</b> South America <Input
                              onChange={this.handleAnswerChange.bind(this, 16)}
                              value={answers[16]}
                              className="blank"
                              inputProps={{size:identification}}
                            />
                            <br/><br/>
                            <b>18.</b> North America <Input
                              onChange={this.handleAnswerChange.bind(this, 17)}
                              value={answers[17]}
                              className="blank"
                              inputProps={{size:identification}}
                            />
                            <br/><br/>
                            <b>19.</b> Europe <Input
                              onChange={this.handleAnswerChange.bind(this, 18)}
                              value={answers[18]}
                              className="blank"
                              inputProps={{size:identification}}
                            />
                            <br/><br/>
                            <b>20.</b> Africa <Input
                              onChange={this.handleAnswerChange.bind(this, 19)}
                              value={answers[19]}
                              className="blank"
                              inputProps={{size:identification}}
                            />
                          </Typography>
                         </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              :
              currentPage === 3?
              <Grid container spacing={16}>
                <Grid item lg={12}>
                  <Paper className={classes.paper4} ref={(node) => this.ref = node}>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <div className="center-div">
                            <Typography className="q-content">
                              <b>{"SECTION 3 "}<i>{" Questions 21-30 "}</i></b>
                              <i>
                              {"\nQuestions 21 and 22\n"}
                              {"Choose "} <b>{"TWO"}</b> {" letters, "} <b>{"A - E\n"}</b>
                              </i>
                            </Typography>
                            <br/>
                            <Typography>
                              {"Which "}<b> {"TWO"}</b>{" hobbies was Thor Heyerdahl very interested in as a youth? "}
                            </Typography>
                            <br/>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup>
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="21"
                                      value="A"
                                      checked={orAnswers[0].indexOf('A') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="A. camping"
                                />
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="21"
                                      value="B"
                                      checked={orAnswers[0].indexOf('B') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="B. climbing"
                                />
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="21"
                                      value="C"
                                      checked={orAnswers[0].indexOf('C') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="C. collecting"
                                />
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="21"
                                      value="D"
                                      checked={orAnswers[0].indexOf('D') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="D. hunting"
                                />
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="21"
                                      value="E"
                                      checked={orAnswers[0].indexOf('E') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="E. reading"
                                />
                              </FormGroup>
                            </FormControl>
                            <br/>
                            <Typography className="q-content">
                              <i>
                              {"\nQuestions 23 and 24\n"}
                              {"Choose "} <b>{"TWO"}</b> {" letters, "} <b>{"A - E\n"}</b>
                              </i>
                            </Typography>
                            <br/>
                            <Typography>
                              {"Which do the speakers say are the "}<b> {"TWO"}</b>{" reasons why Heyerdahl went to live on an island?"}
                            </Typography>
                            <br/>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormGroup>
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="22"
                                      value="A"
                                      checked={orAnswers[1].indexOf('A') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="A.	to examine ancient carvings"
                                />
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="22"
                                      value="B"
                                      checked={orAnswers[1].indexOf('B') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="B.	to experience an isolated place"
                                />
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="22"
                                      value="C"
                                      checked={orAnswers[1].indexOf('C') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="C.	to formulate a new theory"
                                />
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="22"
                                      value="D"
                                      checked={orAnswers[1].indexOf('D') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="D.	to learn survival skills"
                                />
                                <FormControlLabel
                                  className="chkbox-answer"
                                  control={
                                    <Checkbox
                                      name="22"
                                      value="E"
                                      checked={orAnswers[1].indexOf('E') > -1}
                                      onClick={this.handleOrAnswerChange.bind(this)}
                                    />
                                  }
                                  label="E.	to study the impact of an extreme environment"
                                />
                              </FormGroup>
                            </FormControl>
                            <br/><br/>
                            <Typography>
                              <i>{"Questions 25 - 30"}</i>
                            </Typography>
                            <Typography>
                              <i>{"Choose the correct letter "} <b>A, B</b> or <b>C</b></i>
                            </Typography>
                            <br/>
                            <div>
                              <span className="questions">
                                25.	According to Victor and Olivia, academics thought that Polynesian migration from the east was impossible due to
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 25"
                                  name="q25"
                                  value={answers[24]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 24)} control={<Radio />} label="A.	the fact that Eastern countries were far away." />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 24)} control={<Radio />} label="B.	the lack of materials for boat building." />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 24)} control={<Radio />} label="C.	the direction of the winds and currents." />
                                </RadioGroup>
                              </span>
                            </div>
                            <br/>
                            <div>
                              <span className="questions">
                                26.	Which do the speakers agree was the main reason for Heyerdahl’s raft journey?
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 26"
                                  name="q26"
                                  value={answers[25]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 25)} control={<Radio />} label="A.	to overcome a research setback" />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 25)} control={<Radio />} label="B.	to demonstrate a personal quality" />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 25)} control={<Radio />} label="C.	to test a new theory" />
                                </RadioGroup>
                              </span>
                            </div>
                            <br/>
                            <div>
                              <span className="questions">
                                27.	What was most important to Heyerdahl about his raft journey?
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 27"
                                  name="q27"
                                  value={answers[26]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 26)} control={<Radio />} label="A.	the fact that he was the first person to do it" />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 26)} control={<Radio />} label="B.	the speed of crossing the Pacific" />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 26)} control={<Radio />} label="C.	the use of authentic construction methods" />
                                </RadioGroup>
                              </span>
                            </div>
                            <br/>
                            <div>
                              <span className="questions">
                                28.	Why did Heyerdahl go to Easter Island?
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 28"
                                  name="q28"
                                  value={answers[27]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 27)} control={<Radio />} label="A.	to build a stone statue" />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 27)} control={<Radio />} label="B.	to sail a reed boat" />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 27)} control={<Radio />} label="C.	to learn the local language" />
                                </RadioGroup>
                              </span>
                            </div>
                            <br/>
                            <div>
                              <span className="questions">
                                29.	In Olivia’s opinion, Heyerdahl’s greatest influence was on
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 29"
                                  name="q29"
                                  value={answers[28]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 28)} control={<Radio />} label="A.	theories about Polynesian origins." />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 28)} control={<Radio />} label="B.	the development of archaeological methodology." />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 28)} control={<Radio />} label="C.	establishing archaeology as an academic subject." />
                                </RadioGroup>
                              </span>
                            </div>
                            <br/>
                            <div>
                              <span className="questions">
                                30.	Which criticism do the speakers make of William Oliver’s textbook?
                              </span>
                              <br/>
                              <span className="questions">
                                <RadioGroup
                                  aria-label="question 30"
                                  name="q30"
                                  value={answers[29]}
                                  className={classes.group}
                                >
                                  <FormControlLabel className="answer-radio" value="A" onClick={this.handleAnswerChange.bind(this, 29)} control={<Radio />} label="A.	Its style is out of date." />
                                  <FormControlLabel className="answer-radio" value="B" onClick={this.handleAnswerChange.bind(this, 29)} control={<Radio />} label="B.	Its content is over-simplified." />
                                  <FormControlLabel className="answer-radio" value="C" onClick={this.handleAnswerChange.bind(this, 29)} control={<Radio />} label="C.	Its methodology is flawed." />
                                </RadioGroup>
                              </span>
                            </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              :
              <Grid container spacing={16}>
                <Grid item lg={12}>
                  <Paper className={classes.paper4} ref={(node) => this.ref = node}>
                    <Grid container spacing={16}>
                      <Grid item lg={12}>
                        <div className="center-div">
                          <Typography>
                            <b>SECTION 4 <i>Questions 31-40</i></b>
                          </Typography>
                          <Typography>
                            <i>{"Complete the notes below."}</i>
                          </Typography>
                          <Typography>
                            <i>Write <b>ONE WORD ONLY</b> for each answer.</i>
                          </Typography>
                          <br/>
                          <div className="center-box">
                            <Typography variant="headline" component="h6" className="text-center">
                              <b>{"THE FUTURE OF MANAGEMENT"}</b>
                            </Typography>
                            <br/>
                            <Typography>
                              <b>Business markets</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>greater <b>31</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 30)}
                                    value={answers[30]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> among companies
                                </li>
                                <li>increase in power of large  <b>32</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 31)}
                                    value={answers[31]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> companies
                                </li>
                                <li>rising <b>33</b>&nbsp;
                                  <Input
                                      onChange={this.handleAnswerChange.bind(this, 32)}
                                      value={answers[32]}
                                      className="blank"
                                      inputProps={{size:identification}}
                                    /> in certain countries
                                </li>
                              </ul>
                            </Typography>
                            <Typography>
                              <b>External influences on businesses</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>more discussion with <b>34</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 33)}
                                    value={answers[33]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> before making business decisions
                                </li>
                                <li>•	environmental concerns which may lead to more <b>35</b>&nbsp;
                                    <Input
                                    onChange={this.handleAnswerChange.bind(this, 34)}
                                    value={answers[34]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                              </ul>
                            </Typography>
                            <Typography>
                              <b>Business structures</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>more teams will be formed to work on a particular <b>36</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 35)}
                                    value={answers[35]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                                <li>businesses may need to offer hours that are <b>37</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 36)}
                                    value={answers[36]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> or the chance to work remotely
                                </li>
                              </ul>
                            </Typography>
                            <Typography>
                              <b>Management styles</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>
                                  increasing need for managers to provide good <b>38</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 37)}
                                    value={answers[37]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  />
                                </li>
                                <li>changes influenced by <b>39</b> &nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 38)}
                                    value={answers[38]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> taking senior roles
                                </li>
                              </ul>
                            </Typography>
                            <Typography>
                              <b>Changes in the economy</b>
                            </Typography>
                            <Typography>
                              <ul>
                                <li>service sector continues to be important</li>
                                <li>increasing value of intellectual property</li>
                                <li>more and more <b>40</b>&nbsp;
                                  <Input
                                    onChange={this.handleAnswerChange.bind(this, 39)}
                                    value={answers[39]}
                                    className="blank"
                                    inputProps={{size:identification}}
                                  /> workers
                                </li>
                              </ul>
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
          }
        </div>
            <Grid container spacing={16}>
          <Grid item lg={6}>
            <PageNavigation/>
          </Grid>
          <Grid item lg={6} className={classes.textRight}>
            <Button
              key="submitBtn"
              onClick={this.confirmSubmit.bind(this)}
              color="secondary"
              variant="contained"
              size="small"
              className={classes.pageBtn}
              disabled={sendingRequest}
            >
              {"Finish Listening Test Early And Proceed To Reading Test"}&nbsp;<DoneOutlineIcon fontSize="inherit" />
            </Button>
          </Grid>
        </Grid>
            <ConfirmDialog {...this.state} />
            { currentIndex > 3 ? null : <Sound {...this.state}/> }
          </div>
        }
      </div>
    );
  }
}

Listening.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    name         : state.registration.user.name,
    email        : state.registration.user.email,
    user_id      : state.registration.user.id,
    done         : state.registration.user.done,
    currentPage  : state.listeningExam.pageNumber,
    maxPage      : state.listeningExam.maxPage,
    examTime     : state.listeningExam.examTime,
    answers      : state.listeningExam.answers,
    orAnswers    : state.listeningExam.orAnswers,
    startAudio   : state.listeningExam.startAudio,
    audioUrls    : state.listeningExam.audioUrls,
    currentIndex : state.listeningExam.currentIndex,
    triggered    : state.listeningExam.triggered,
    finish       : state.loadingPage.currentIndex,
    examSet      : state.app.examSet,
    sendingRequest : state.app.sendingRequest,
  }
};

export default connect(mapStateToProps)(withStyles(styles)(Listening));