import {
  UPDATE_SENDING_REQUEST,
  UPDATE_CURRENT_PAGE,
  UPDATE_EXAM_SET,
} from '../types/app';

export const updateSendingRequest = (sendingRequest) => {
  return dispatch => {
    dispatch({
      type    : UPDATE_SENDING_REQUEST,
      payload : sendingRequest,
    });
  };
};

export const updatePage = (page) => {
  return dispatch => {
    dispatch({
      type    : UPDATE_CURRENT_PAGE,
      payload : page,
    });
  };
};

export const updateExamSet = (set) => {
  return dispatch => {
    dispatch({
      type    : UPDATE_EXAM_SET,
      payload : set,
    });
  }
}