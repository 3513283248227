import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import {
  browserVersion,
  osName,
  browserName,
  osVersion,
  isMobile,
} from "react-device-detect";

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  blink: {
    backgroundColor: "red",
    animation: "blinker 1s linear infinite",
    color: "white",
  },
});

class Loading extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              variant="headline"
              component="h6"
              className={classes.textCenter}
            >
              Your browser is currently incompatible to use this app.
              <br />
              <br />
              You are currently using{" "}
              <b>
                {osName} {osVersion} {isMobile ? " (Mobile)" : ""}
              </b>{" "}
              as your operating system and{" "}
              <b>
                {browserName} {browserVersion}
              </b>{" "}
              as your browser.
              <br />
              <br />
              This test requires you to use non-mobile or desktop Chrome
              (version 51 or higher) or Firefox (version 31 or higher).
            </Typography>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loadTime: state.loadingPage.loadTime,
    messages: state.loadingPage.messages,
    btnMessages: state.loadingPage.btnMessages,
    currentIndex: state.loadingPage.currentIndex,
    nextPages: state.loadingPage.nextPages,
    done: state.registration.user.done,
    currentPage: state.app.currentPage,
    user: state.registration.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Loading));
