import * as actionTypes from '../types/registration'

export const updateUser = (user) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_USER,
      payload: user,
    })
  };
}

export const updateActualCountry = (country) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_USER_ACTUAL_COUNTRY,
      payload: country,
    })
  };
}