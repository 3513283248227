import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import * as examActions from '../../actions/exam/writingExam';
import { sendWritingAnswer } from '../../actions/api/requests';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { updateAlertDialog } from '../../actions/dialog/dialog';
import { updateSendingRequest, updatePage } from '../../actions/app/app';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import wordcount from 'wordcount';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import pink from '@material-ui/core/colors/pink';

const styles = theme => ({
  root: {
    flexGrow: 1,
    ...theme.mixins.gutters(),
    paddingTop    : theme.spacing.unit * 2,
    paddingBottom : theme.spacing.unit * 2,
  },
  paper: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '10vh',
    overflowY : 'scroll',
  },
  paper2: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '80vh',
    height    : '80vh',
    overflowY : 'auto',
    width     : 'inherit',
    textAlign : 'center',
  },
  paper5: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '80vh',
    height    : '80vh',
    overflowY : 'auto',
    width     : 'inherit',
    textAlign : 'center',
  },
  paper4: {
    padding   : theme.spacing.unit * 2,
    color     : theme.palette.text.secondary,
    maxHeight : '80vh',
    height    : '80vh',
    overflowY : 'auto',
    textAlign : 'left !important',
    width     : 'inherit',
  },
  center: {
    textAlign : "center",
  },
  textRight:{
    textAlign : "right",
  },
  pageBtn:{
    margin :"1px",
    height :"1vh",
    textTransform: "none",
  },
  time:{
    verticalAlign:"middle",
  },
  paper3: {
    backgroundColor: pink['A400'],
    color          : 'white',
  },
  blink:{
    backgroundColor : 'red',
    animation       : "blinker 1s linear infinite",
    color           : 'white',
  },
  red:{
    backgroundColor : 'red',
    color           : 'white',
  },
  table: {
    minWidth: "700px",
    width: "70%",
    border: "1px solid rgba(224, 224, 224, 1) !important",
  },
  table3:{
    minWidth: "700px",
    width: "70%",
  },
});

class WritingAC extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      handleAgree : this.finishExam.bind(this),
      dialogTitle : "Confirm",
      dialogText  : "Are you sure you want to finish the Writing Exam and move to the Speaking Exam?",
      yesBtnTitle : "YES",
      noBtnTitle  : "NO",
    };
  }
  changePage(page){
    const element = ReactDOM.findDOMNode(this.ref);
    if (element !== null) {
      element.scrollTo(0,0);
    }
    try {
      window.scrollTo(0, 0);
    } catch (e){}
    const { dispatch } = this.props;
    dispatch(examActions.updatePageNumber(page));
  }
  tick(){
    const { dispatch, examTime } = this.props;
    const newTime = examTime-1;
    if (newTime >= 0){
      dispatch(examActions.setExamTime(examTime-1));
    } else {
      clearInterval(this.timer);
      this.finishExam();
    }
  }
  componentDidMount(){
    try {
      window.scrollTo(0, 0);
    } catch (e){}
    this.timer = setInterval(this.tick.bind(this), 1000);
    const { dispatch } = this.props;
    dispatch(updateSendingRequest(false));
    const urlParams = new URLSearchParams(window.location.search);
    const timer = urlParams.get('change_timer');
    if (timer){
      dispatch(examActions.setExamTime(parseInt(timer, 10)));
    }
  }
  componentWillUnmount(){
    clearInterval(this.timer);
    const { dispatch } = this.props;
    dispatch(examActions.reset());
  }
  handleAnswerChange(index, e){
    const { dispatch } = this.props;
    dispatch(examActions.updateAnswer(e.target.value, index));
  }
  confirmSubmit(){
    const { dispatch } = this.props
    dispatch(updateAlertDialog(true));
  }
  finishExam(){
    const { dispatch, answers, name, email, user_id } = this.props;
    dispatch(updateSendingRequest(true));
    dispatch(updateAlertDialog(false));
    dispatch(sendWritingAnswer(JSON.stringify(answers), name, email, user_id));
  }
  render(){
    const {
      currentPage,
      maxPage,
      examTime,
      classes,
      answers,
      done,
      dispatch,
      sendingRequest,
      examSet,
    } = this.props;
    if (done){
      dispatch(updatePage(5));
    }
    const examMin = Math.floor(examTime / 60);
    const examSec = examTime - examMin * 60;
    const PageNavigation = () => {
      let result = [];
      for (let i = 1; i <= maxPage; i++){
        result.push(
          <Button
            key={"page"+i}
            variant={i!==currentPage?"contained":"outlined"}
            color="secondary"
            size="small"
            className={classes.pageBtn}
            onClick={this.changePage.bind(this, i)}
          >
            {"Part " + i}
          </Button>
        );
      }
      return result;
    }

    let clockClass = classes.paper3;
    if ((examMin === 10 && examSec === 0) ||
        (examMin === 5 && examSec === 0)  ||
        ((examMin === 9 && examSec < 60) && (examMin === 9 && examSec > 50)) ||
        ((examMin === 4 && examSec < 60) && (examMin === 4 && examSec > 50))){
      clockClass = classes.blink;
    } else if (examMin < 10 ) {
      clockClass = classes.red;
    }

    return (
      <div>
        {
           examSet === 1?
            <div className={classes.root}>
        <div>
          <Grid container spacing={16}>
              <Grid item md={12}>
                <Paper>
                  <Typography
                    component="p"
                    color="secondary"
                    align="center"
                    className={clockClass}
                  >
                    <AccessAlarmIcon fontSize="inherit" className={classes.time}/> Time remaining: {examMin}m {examSec}s
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          {
            currentPage === 1?
              <Grid container spacing={16}>
                <Grid item md={6} className={classes.center}>
                  <Paper className={classes.paper2}>
                    <div className="text-left">
                      <Typography variant="headline" component="h6">
                        Writing Part 1
                      </Typography>
                      <Typography component="p" className="q-content">
                        {"You should spend about 20 minutes on this task.\n\nThe first chart below shows how energy is used in an average Australian household. The second chart shows the greenhouse gas emissions which result from this energy use.\n\nSummarise the information by selecting and reporting the main features, and make comparisons where relevant.\n\nWrite at least 150 words."}
                      </Typography>
                    </div>
                    <br/>
                    <img height="70%" alt="table" src="https://res.cloudinary.com/dgytquyfl/image/upload/v1537491668/image_1_rb6zva.jpg"/>
                  </Paper>
                </Grid>
                <Grid item md={6}>
                  <Grid item md={12}>
                    <Paper className={classes.paper2}>
                      <textarea
                        onChange={this.handleAnswerChange.bind(this, 0)}
                        value={answers[0]}
                        className="blank textfield-answer"
                        width="95%"
                      />
                      <br/>
                      <Typography component="p">
                        <b>Word Count:</b>&nbsp;{wordcount(answers[0])}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            :
              <Grid container spacing={16}>
                <Grid item md={6} className={classes.center}>
                  <Paper className={classes.paper4}>
                    <Typography variant="headline" component="h6">
                      Writing Part 2
                    </Typography>
                    <Typography component="p" className="q-content">
                      {"You should spend about 40 minutes on this task.\n\nPresent a written argument or case to an educated reader with no specialist knowledge of the following topic.\n\nGive reasons for your answer and include any relevant examples from your own knowledge or experience.\n\nWrite at least 250 words."}
                    </Typography>
                    <br/>
                    <div className="black-box">
                      <br/>
                      <Typography variant="headline" component="h6">
                        <b>It is important for children to learn the difference between right and wrong at an early age. Punishment is necessary to help them learn this distinction. </b>
                      </Typography>
                      <br/>
                      <Typography variant="headline" component="h6">
                        <b>To what extent do you agree or disagree with this opinion? </b>
                      </Typography>
                      <br/>
                      <Typography variant="headline" component="h6">
                        <b>What sort of punishment should parents and teachers be allowed to use to teach good behavior to children? </b>
                      </Typography>
                      <br/>
                    </div>
                  </Paper>
                </Grid>
                <Grid item md={6}>
                  <Grid item md={12}>
                    <Paper className={classes.paper2}>
                      <textarea
                        onChange={this.handleAnswerChange.bind(this, 1)}
                        value={answers[1]}
                        className="blank textfield-answer"
                      />
                      <br/>
                      <Typography component="p">
                        <b>Word Count:</b>&nbsp;{wordcount(answers[1])}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
          }
        </div>
        <Grid container spacing={16}>
          <Grid item md={6}>
            <PageNavigation/>
          </Grid>
          <Grid item md={6} className={classes.textRight}>
            <Button
              key="submitBtn"
              onClick={this.confirmSubmit.bind(this)}
              color="secondary"
              variant="contained"
              size="small"
              className={classes.pageBtn}
              disabled={sendingRequest}
            >
              {"Finish Writing Test Early And Proceed To Speaking Test"}&nbsp;<DoneOutlineIcon fontSize="inherit" />
            </Button>
          </Grid>
        </Grid>
        <ConfirmDialog {...this.state} />
        </div>
          :
            <div className={classes.root}>
              <div>
                <Grid container spacing={16}>
                    <Grid item md={12}>
                      <Paper>
                        <Typography
                          component="p"
                          color="secondary"
                          align="center"
                          className={clockClass}
                        >
                          <AccessAlarmIcon fontSize="inherit" className={classes.time}/> Time remaining: {examMin}m {examSec}s
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                {
                  currentPage === 1?
                    <Grid container spacing={16}>
                      <Grid item md={6}>
                        <Paper className={classes.paper5}>
                          <div className="text-left">
                            <Typography variant="headline" component="h6">
                              Writing Part 1
                            </Typography>
                            <Typography component="p" className="q-content">
                              {"You should spend about 20 minutes on this task.\n"}
                              {"Write at 150 words."}
                            </Typography>
                          </div>
                          <br/>
                          <div className="center-box">
                            <Typography>
                              <b><i>{"The tables below give information about sales of Fairtrade*-labelled coffee and bananas in 1999 and 2004 in five European countries."}</i></b>
                            </Typography>
                            <br/>
                            <Typography>
                              <b><i>{"Summarise the information by selecting and reporting the main features, and make comparisons where relevant."}</i></b>
                            </Typography>
                          </div>
                          <br/><br/><br/>
                          <Typography variant="headline" component="h6" className="text-center">
                            <b>Sales of Fairtrade-labelled coffe and bananas (1999 & 2004)</b>
                          </Typography>
                          <br/>
                          <table width="100%" className={classes.table} cellPadding="10" cellSpacing="0" align="center" textAlign="center">
                            <tbody className="table2">
                              <tr>
                                <td align="center" width="33%">
                                  <Typography><b>Coffee</b></Typography>
                                </td>
                                <td align="center" width="33%">
                                  <Typography><b>1999 (millions of euros)</b></Typography>
                                </td>
                                <td align="center" width="33%">
                                  <Typography><b>2004 (millions of euros)</b></Typography>
                                </td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>UK</Typography></td>
                                <td align="center"><Typography>1.5</Typography></td>
                                <td align="center"><Typography>20</Typography></td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>Switzerland</Typography></td>
                                <td align="center"><Typography>3</Typography></td>
                                <td align="center"><Typography>6</Typography></td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>Denmark</Typography></td>
                                <td align="center"><Typography>1.8</Typography></td>
                                <td align="center"><Typography>2</Typography></td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>Belgium</Typography></td>
                                <td align="center"><Typography>1</Typography></td>
                                <td align="center"><Typography>1.7</Typography></td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>Sweden</Typography></td>
                                <td align="center"><Typography>0.8</Typography></td>
                                <td align="center"><Typography>1</Typography></td>
                              </tr>
                            </tbody>
                          </table>
                          <br/><br/>
                          <table width="100%" className={classes.table} cellPadding="10" cellSpacing="0" align="center" textAlign="center">
                            <tbody className="table2">
                              <tr>
                                <td align="center" width="33%">
                                  <Typography><b>Bananas</b></Typography>
                                </td>
                                <td align="center" width="33%">
                                  <Typography><b>1999 (millions of euros)</b></Typography>
                                </td>
                                <td align="center" width="33%">
                                  <Typography><b>2004 (millions of euros)</b></Typography>
                                </td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>Switzerland</Typography></td>
                                <td align="center"><Typography>15</Typography></td>
                                <td align="center"><Typography>47</Typography></td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>UK</Typography></td>
                                <td align="center"><Typography>1</Typography></td>
                                <td align="center"><Typography>5.5</Typography></td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>Belgium</Typography></td>
                                <td align="center"><Typography>0.6</Typography></td>
                                <td align="center"><Typography>4</Typography></td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>Sweden</Typography></td>
                                <td align="center"><Typography>1.8</Typography></td>
                                <td align="center"><Typography>1</Typography></td>
                              </tr>
                              <tr>
                                <td align="center"><Typography>Denmark</Typography></td>
                                <td align="center"><Typography>2</Typography></td>
                                <td align="center"><Typography>0.9</Typography></td>
                              </tr>
                            </tbody>
                          </table>
                          <br/>
                          <table width="100%" className={classes.table3} cellPadding="10" cellSpacing="0" align="center" textAlign="center">
                            <thead>
                              <tr>
                                <td colSpan="3">
                                  <Typography>
                                    * Fairtrade: a category of products for which farmers from developing countries have been paid an officially agreed fair price.
                                  </Typography>
                                </td>
                              </tr>
                            </thead>
                          </table>
                          <br/>
                        </Paper>
                      </Grid>
                      <Grid item md={6}>
                        <Grid item md={12}>
                          <Paper className={classes.paper2}>
                            <textarea
                              onChange={this.handleAnswerChange.bind(this, 0)}
                              value={answers[0]}
                              className="blank textfield-answer"
                              width="95%"
                            />
                            <br/>
                            <Typography component="p">
                              <b>Word Count:</b>&nbsp;{wordcount(answers[0])}
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  :
                    <Grid container spacing={16}>
                      <Grid item md={6} className={classes.center}>
                        <Paper className={classes.paper4}>
                          <Typography variant="headline" component="h6">
                            Writing Part 2
                          </Typography>
                          <Typography component="p" className="q-content">
                            {"You should spend about 40 minutes on this task."}
                          </Typography>
                          <Typography>
                            Write at least 250 words.
                          </Typography>
                          <br/>
                          <Typography>
                            Write about the following topic:
                          </Typography>
                          <br/>
                          <div className="black-box">
                            <br/>
                            <Typography>
                              <b>Some people think that all university students should study whatever they like. Others believe that they should only be allowed to study subjects that will be useful in the future, such as those related to science and technology.</b>
                            </Typography>
                            <br/>
                            <Typography>
                              <b>Discuss both these views and give your own opinion. </b>
                            </Typography>
                            <br/>
                          </div>
                          <br/>
                          <Typography>
                           Give reasons for your answer and include any relevant examples from your own knowledge or experience.
                          </Typography>
                          <br/>
                        </Paper>
                      </Grid>
                      <Grid item md={6}>
                        <Grid item md={12}>
                          <Paper className={classes.paper2}>
                            <textarea
                              onChange={this.handleAnswerChange.bind(this, 1)}
                              value={answers[1]}
                              className="blank textfield-answer"
                            />
                            <br/>
                            <Typography component="p">
                              <b>Word Count:</b>&nbsp;{wordcount(answers[1])}
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                }
              </div>
              <Grid container spacing={16}>
                <Grid item md={6}>
                  <PageNavigation/>
                </Grid>
                <Grid item md={6} className={classes.textRight}>
                  <Button
                    key="submitBtn"
                    onClick={this.confirmSubmit.bind(this)}
                    color="secondary"
                    variant="contained"
                    size="small"
                    className={classes.pageBtn}
                    disabled={sendingRequest}
                  >
                    {"Finish Writing Test Early And Proceed To Speaking Test"}&nbsp;<DoneOutlineIcon fontSize="inherit" />
                  </Button>
                </Grid>
              </Grid>
              <ConfirmDialog {...this.state} />
            </div>
        }
      </div>
    );
  }
}

WritingAC.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    name        : state.registration.user.name,
    email       : state.registration.user.email,
    user_id     : state.registration.user.id,
    user        : state.registration.user,
    currentPage : state.writingExam.pageNumber,
    maxPage     : state.writingExam.maxPage,
    examTime    : state.writingExam.examTime,
    answers     : state.writingExam.answers,
    finish      : state.loadingPage.currentIndex,
    done        : state.registration.user.done,
    examSet     : state.app.examSet,
    sendingRequest : state.app.sendingRequest,
  }
};

export default connect(mapStateToProps)(withStyles(styles)(WritingAC));