import * as actions from "../actions/types/exam";
import { updateObjectInArray } from "../utils/updateObjectInArray";

const defaultState = {
  pageNumber: 1,
  maxPage: 4,
  examTime: 120,
  answers: new Array(40).join(".").split("."),
  orAnswers: [[], []],
  startAudio: false,
  currentIndex: 0,
  position: 0,
  triggered: false,
  audioUrls: [
    [
      "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/c10/C10+-+Test+1+-+Section+1.mp3",
      "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/c10/C10+-+Test+1+-+Section+2.mp3",
      "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/c10/C10+-+Test+1+-+Section+3.mp3",
      "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/c10/C10+-+Test+1+-+Section+4.mp3",
    ],
    [
      "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/c10t2/Cambridge+IELTS+10+Listening+Test+2+-+Section+1.mp3",
      "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/c10t2/Cambridge+IELTS+10+Listening+Test+2+-+Section+2.mp3",
      "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/c10t2/Cambridge+IELTS+10+Listening+Test+2+-+Section+3.mp3",
      "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/c10t2/Cambridge+IELTS+10+Listening+Test+2+-+Section+4.mp3",
    ],
  ],
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.UPDATE_LISTENING_EXAM_PAGE_NUMBER: {
      return { ...state, pageNumber: action.payload };
    }
    case actions.SET_LISTENING_EXAM_TIME: {
      return { ...state, examTime: action.payload };
    }
    case actions.UPDATE_LISTENING_EXAM_ANSWER: {
      return { ...state, answers: updateObjectInArray(state.answers, action) };
    }
    case actions.UPDATE_LISTENING_EXAM_OR_ANSWER: {
      return { ...state, orAnswers: action.payload };
    }
    case actions.UPDATE_LISTENING_EXAM_AUDIO_PLAYING: {
      return { ...state, startAudio: action.payload };
    }
    case actions.UPDATE_LISTENING_EXAM_CURRENT_AUDIO_INDEX: {
      return { ...state, currentIndex: action.payload };
    }
    case actions.UPDATE_AUDIO_POSITION: {
      return { ...state, position: action.payload };
    }
    case actions.UPDATE_COUNTDOWN_TRIGGER: {
      return { ...state, triggered: action.payload };
    }
    case actions.RESET_EXAM_SETTINGS: {
      return { ...defaultState };
    }
    default:
      return { ...state };
  }
}
