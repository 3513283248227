import * as actions from '../types/loadingPage'

export const setLoadTime = (time) => {
  return dispatch => {
    dispatch({
      type: actions.SET_LOADING_PAGE_TIME,
      payload: time
    });
  };
};

export const updateCurrentIndex = (index) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_LOADING_PAGE_CURRENT_INDEX,
      payload: index
    })
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type   : actions.RESET_LOAD_PAGE_SETTINGS,
    });
  };
}