import * as actions from '../types/exam'

export const updatePageNumber = (number) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_LISTENING_EXAM_PAGE_NUMBER,
      payload: number
    });
  };
};

export const setExamTime = (time) => {
  return dispatch => {
    dispatch({
      type: actions.SET_LISTENING_EXAM_TIME,
      payload: time
    });
  };
};

export const updateAnswer = (answer, index) => {
  return dispatch => {
    dispatch({
      type   : actions.UPDATE_LISTENING_EXAM_ANSWER,
      answer : answer,
      index  : index,
    });
  };
};

export const updateOrAnswer = (answer) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_LISTENING_EXAM_OR_ANSWER,
      payload: answer
    });
  };
};

export const updateAudioPlaying = (audioPlaying) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_LISTENING_EXAM_AUDIO_PLAYING,
      payload : audioPlaying,
    });
  };
};

export const updateCurrentAudioIndex = (index) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_LISTENING_EXAM_CURRENT_AUDIO_INDEX,
      payload : index,
    });
  };
};

export const updateAudioPosition = (position) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_AUDIO_POSITION,
      payload : position,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type   : actions.RESET_EXAM_SETTINGS,
    });
  };
};

export const updateCountdownTrigger = (triggered) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_COUNTDOWN_TRIGGER,
      payload : triggered
    });
  };
};