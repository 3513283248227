import { 
  TOGGLE_ALERT_DIALOG, 
  TOGGLE_WARNING_DIALOG,
  SET_ALERT_DIALOG
} from '../types/dialog'

export const toggleAlertDialog = () => {
  return dispatch => {
    dispatch({
      type: TOGGLE_ALERT_DIALOG,
    })
  };
}

export const updateAlertDialog = (status) => {
  return dispatch => {
    dispatch({
      type    : SET_ALERT_DIALOG,
      payload : status,
    })
  };
}

export const toggleWarningDialog = () => {
  return dispatch => {
    dispatch({
      type: TOGGLE_WARNING_DIALOG,
    })
  };
}