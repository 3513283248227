import * as actions from "../actions/types/exam";

const defaultState = {
  startExam: false,
  timer: 0,
  currentIndex: 0,
  position: 0,
  playingAudio: false,
  recording: false,
  audioUrls: [
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+01.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+02.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+03.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+04.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+05.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+06.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+07.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+08.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+09.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+10.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+11.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+12.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+13.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+14.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+15.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+16.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+17.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+18.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+19.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+20.mp3",
    "https://s3-ap-southeast-1.amazonaws.com/ielts-recordings/SP01/SP01+-+21.mp3",
  ],
  recordingIndex: 0,
  recordingDurations: [
    15, 15, 15, 30, 30, 30, 30, 30, 30, 30, 30, 30, 120, 45, 45, 45, 45, 45, 45,
  ],
  timer2Status: false,
  timer2: 60,
  skipExam: false,
  showResume: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.UPDATE_SPEAKING_EXAM_CURRENT_AUDIO_INDEX: {
      return { ...state, currentIndex: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_AUDIO_POSITION: {
      return { ...state, position: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_RECORDING_STATUS: {
      return { ...state, recording: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_AUDIO_PLAYING_STATUS: {
      return { ...state, playingAudio: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_TIMER: {
      return { ...state, timer: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_TIMER2: {
      return { ...state, timer2: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_RECORDING_INDEX: {
      return { ...state, recordingIndex: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_PART2_STATUS: {
      return { ...state, timer2Status: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_START_STATUS: {
      return { ...state, startExam: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_SKIP_EXAM: {
      return { ...state, skipExam: action.payload };
    }
    case actions.UPDATE_SPEAKING_EXAM_SHOW_RESUME: {
      return { ...state, showResume: action.payload };
    }
    default:
      return { ...state };
  }
}
