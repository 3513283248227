import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import App from './App';
import MicTest from './components/others/MicTest'

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/testmic" exact component={MicTest} />
        <Route component={App} />
      </Switch>
    </Router>
  );
}

export default Routes;